import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPaperPlane, faHandHoldingUsd, faUser } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4 flex justify-between items-center lg:hidden">
      <FontAwesomeIcon icon={faHome} onClick={() => navigate('/')} className="text-blue-600 cursor-pointer" />
      <FontAwesomeIcon icon={faPaperPlane} onClick={() => navigate('/send-and-request')} className="text-blue-600 cursor-pointer" />
      <FontAwesomeIcon icon={faHandHoldingUsd} onClick={() => navigate('/Currencies')} className="text-blue-600 cursor-pointer" />
      <FontAwesomeIcon icon={faUser} onClick={() => navigate('/settings/profile')} className="text-blue-600 cursor-pointer" />
    </div>
  );
}

export default Footer;
