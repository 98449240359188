import React, { useState, useEffect } from 'react';
import { Card, Row, Container, Col, Table, Form, Button, Modal, Spinner, ToggleButton, ToggleButtonGroup, Pagination } from 'react-bootstrap';
import api from '../../api';
import { useNavigate, Link } from "react-router-dom";
import "./admin.Module.css";


const MAX_PAGES_SHOWN = 5;

const getPaginationRange = (current, total, range = MAX_PAGES_SHOWN) => {
    let start = current - Math.floor(range / 2);
    start = Math.max(start, 1);
    let end = start + range - 1;
    end = Math.min(end, total);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

const Admin = () => {
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [totalUsers, setTotalUsers] = useState(0);
    const [bannedUsers, setBannedUsers] = useState(0);
    const [unverifiedUsers, setUnverifiedUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const navigate = useNavigate();
    const [totalWithdrawals, setTotalWithdrawals] = useState(0);
    const [totalBonus, setTotalBonus] = useState(0);
    const [pendingWithdrawals, setPendingWithdrawals] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [buttonLoading, setButtonLoading] = useState({});



    const showDeleteConfirmation = (id) => {
        setUserToDelete(id);
        setShowDeleteModal(true);
    };




    const viewMoreDetails = (user) => {
        navigate(`/details/${user._id}`);
    }

    useEffect(() => {
        async function fetchUserCounts() {
            try {
                const response = await api.get('api/users-counts');
                const data = response.data;
                setTotalUsers(data.totalUsers);
                setBannedUsers(data.bannedUsers);
                setUnverifiedUsers(data.unverifiedUsers);
            } catch (error) {
                console.error('Failed to fetch user counts:', error);
            }
        }

        async function fetchUsers() {
            try {
                let query = '';
                if (selectedFilter !== 'All') {
                    query += `filter=${selectedFilter}&`;
                }
                if (search) {
                    query += `search=${search}`;
                }

                const response = await api.get(`api/users?page=${currentPage}&limit=${itemsPerPage}&${query}`);
                const data = response.data;

                const retrievedTotalItems = data.totalItems || data.meta?.totalItems || data.pagination?.totalItems;

                setTotalItems(retrievedTotalItems);
                setUsers(data.users || data.data);
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        }

        async function fetchWithdrawalsCount() {
            try {
                const response = await api.get('api/withdrawals-count');
                setTotalWithdrawals(response.data.totalWithdrawalsAmount);

            } catch (error) {
                console.error('Failed to fetch withdrawals count:', error);
            }
        }

        async function fetchBonusCount() {
            try {
                const response = await api.get('api/bonus-count');
                setTotalBonus(response.data.totalBonus);
            } catch (error) {
                console.error('Failed to fetch bonus count:', error);
            }
        }

        async function fetchPendingWithdrawalsCount() {
            try {
                const response = await api.get('api/pending-withdrawals-count');
                setPendingWithdrawals(response.data.pendingWithdrawalsCount);
            } catch (error) {
                console.error('Failed to fetch pending withdrawals count:', error);
            }
        }

        (async () => {
            setIsLoading(true);

            await Promise.all([
                fetchUserCounts(),
                fetchUsers(),
                fetchWithdrawalsCount(),
                fetchBonusCount(),
                fetchPendingWithdrawalsCount()
            ]);

            setIsLoading(false);
        })();

    }, [selectedFilter, search, currentPage, itemsPerPage]);


    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginationRange = getPaginationRange(currentPage, totalPages);
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                {i}
            </Pagination.Item>
        );
    }


    const handleToggle = async (userId) => {
        console.log("handleToggle called with userId:", userId);
        const userToUpdate = users.find(user => user._id === userId);

        if (!userToUpdate) return;

        try {
            let response;
            if (userToUpdate.isBanned) {
                // Call the unban API endpoint
                response = await api.patch(`api/users/unban/${userId}`);
            } else {
                // Call the ban API endpoint
                response = await api.patch(`api/users/ban/${userId}`);
            }

            userToUpdate.isBanned = response.data.isBanned;
            setUsers(prevUsers => prevUsers.map(user => user._id === userId ? userToUpdate : user));

            // Optionally, show a success message
            // Example: toast.success('User status updated successfully!');

        } catch (error) {
            console.error('Failed to update user status:', error.message || error);
            // Optionally, show an error message
            // Example: toast.error('Failed to update user status.');
        }
    };


    const confirmDelete = async () => {
        // Start the spinner/loading state for the user being deleted
        setButtonLoading(prev => ({ ...prev, [userToDelete._id]: true }));
    
        try {
            const response = await api.delete(`api/users/delete/${userToDelete._id}`);
    
            if (response.status === 200) {
                // Remove user from state
                setUsers(prevUsers => prevUsers.filter(user => user._id !== userToDelete._id));
    
                // Hide modal
                setShowDeleteModal(false);
            } else {
                console.error('Failed to delete user:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to delete user:', error.message || error);
        } finally {
            setButtonLoading(prev => ({ ...prev, [userToDelete._id]: false }));
            setUserToDelete(null);
        }
    };
    


    const handleModalClose = () => {
        if (userToDelete) {
            // Reset the spinner/loading state for the user being deleted
            setButtonLoading(prev => ({ ...prev, [userToDelete._id]: false }));
        }
        setUserToDelete(null);
        setShowDeleteModal(false);
    };
    
    


    return (
        <Container fluid className="custom-padding">
            <Row className="custom-margin-bottom">
                {[
                    { icon: "users", title: "Total Users", data: totalUsers },
                    { icon: "ban", title: "Banned Users", data: bannedUsers },
                    { icon: "exclamation-circle", title: "Unverified Users", data: unverifiedUsers },
                    { icon: "money-bill-wave", title: "Total Withdrawals", data: `$${totalWithdrawals.toLocaleString()}` },
                    { icon: "gift", title: "Total Bonus", data: `$${totalBonus.toLocaleString()}` },
                    { icon: "clock", title: "Pending Withdrawals", data: pendingWithdrawals, button: true }
                ].map((item, index) => (
                    <Col md={4} sm={6} xs={12} key={index} className="mb-4">
                        <Card className="h-100 shadow rounded-lg overflow-hidden">
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className={`d-flex align-items-center mb-3 card-title-font`}>
                                    <div className="icon-circle-bg custom-icon-circle mr-3">
                                        <i className={`fas fa-${item.icon} text-white custom-icon-size`}></i>
                                    </div>
                                    {item.title}
                                </Card.Title>
                                <Card.Text className={`flex-grow-1 font-weight-bold text-secondary card-text-font`}>
                                    {item.data}
                                </Card.Text>
                                {item.button &&
                                    <Button
                                        variant="outline-primary"
                                        className="mt-2"
                                        onClick={() => navigate('/pending')}
                                    >
                                        View Details
                                    </Button>}
                            </Card.Body>
                        </Card>
                    </Col>


                ))}
            </Row>

            <Card className="shadow">
                <Card.Body>
                    <Form className="mb-3">
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="filter">
                                    <Form.Control as="select" value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)}>
                                        <option>All</option>
                                        <option>Banned</option>
                                        <option>Unverified</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="search">
                                    <Form.Control type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={user._id}>
                                        <td>{index + 1}</td>
                                        <td>{user.payID}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phoneNumber}</td>
                                        <td>
                                            {user.isBanned ? 'Banned' : user.isVerified ? 'Verified' : 'Unverified'}
                                        </td>
                                        <td>
                                            <Button
                                                className="fixed-size-button"
                                                style={{
                                                    backgroundColor: user.isBanned
                                                        ? 'rgba(220, 53, 69, 0.5)'
                                                        : 'rgba(40, 167, 69, 0.5)',
                                                    borderColor: user.isBanned
                                                        ? 'rgba(220, 53, 69, 0.5)'
                                                        : 'rgba(40, 167, 69, 0.5)',
                                                }}
                                                onClick={() => handleToggle(user._id)}
                                            >
                                                {user.isBanned ? (
                                                    <>
                                                        <i className="fas fa-check"></i> Unban
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="fas fa-ban"></i> Ban
                                                    </>
                                                )}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="fixed-size-button"
                                                style={{
                                                    backgroundColor: 'rgba(0, 123, 255, 0.5)',
                                                    borderColor: 'rgba(0, 123, 255, 0.5)',
                                                }}
                                                onClick={() => viewMoreDetails(user)}
                                            >
                                                <i className="fas fa-eye"></i> View More
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                className="ml-2"
                                                onClick={() => {
                                                    setButtonLoading(prev => ({ ...prev, [user._id]: true }));
                                                    showDeleteConfirmation(user);
                                                }}>

                                                {buttonLoading[user._id] ? <Spinner as="span" animation="border" size="sm" /> : "Delete"}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    <Pagination className="justify-content-center mt-3">
                        <Pagination.First
                            onClick={() => setCurrentPage(1)}
                            disabled={currentPage === 1}
                        />
                        <Pagination.Prev
                            onClick={() =>
                                setCurrentPage((prev) => Math.max(1, prev - 1))
                            }
                            disabled={currentPage === 1}
                        />
                        {currentPage > 1 && <Pagination.Ellipsis disabled />}
                        {paginationRange.map((pageNum) => (
                            <Pagination.Item
                                key={pageNum}
                                active={pageNum === currentPage}
                                onClick={() => setCurrentPage(pageNum)}
                            >
                                {pageNum}
                            </Pagination.Item>
                        ))}
                        {currentPage < totalPages && <Pagination.Ellipsis disabled />}
                        <Pagination.Next
                            onClick={() =>
                                setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                            }
                            disabled={currentPage === totalPages}
                        />
                        <Pagination.Last
                            onClick={() => setCurrentPage(totalPages)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>
                </Card.Body>
            </Card>
            <Modal show={showDeleteModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this user  {userToDelete?.payID}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleModalClose()}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Confirm Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}

export default Admin;