import React, { useState, useEffect } from "react";
import styles from "./Widthdraw.module.css";
import { Navbar } from "../SoleComponents";
import { useUser } from "../context";
import api from "../../api";
import Lottie from "lottie-react";
import successAnimation from "./success-animation.json";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";


const Withdraw = () => {
  const { user } = useUser();
  const accounts = user?.accounts;
  const [showWithdrawalGuide, setShowWithdrawalGuide] = useState(false);
  const [showBonusModal, setShowBonusModal] = useState(false);


  const toggleWithdrawalGuide = () => {
    setShowWithdrawalGuide(!showWithdrawalGuide);
  };



  const getUniqueCurrencies = (accounts) => {
    const uniqueCurrencies = Array.from(new Set(accounts.map((account) => account.currency)));
    uniqueCurrencies.sort((a, b) => {
      const balanceA = accounts.find((account) => account.currency === a)?.balance || 0;
      const balanceB = accounts.find((account) => account.currency === b)?.balance || 0;
      return balanceB - balanceA;
    });
    return uniqueCurrencies;
  };

  const currencies = getUniqueCurrencies(accounts);

  const highestBalanceCurrency = currencies.find((currency) => {
    const balance = accounts.find((account) => account.currency === currency)?.balance || 0;
    return balance > 0;
  });
  const initialSelectedCurrency = highestBalanceCurrency || "USD";
  const [selectedCurrency, setSelectedCurrency] = useState(initialSelectedCurrency);
  const [amountwithdrawal, setAmountWithdrawal] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [withdrawalResponse, setWithdrawalResponse] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pendingWithdrawal, setPendingWithdrawal] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [hasFetchedPendingWithdrawal, setHasFetchedPendingWithdrawal] = useState(false);



  useEffect(() => {
    if (hasFetchedPendingWithdrawal && user?.primaryInfo?.bonus > 50 && !pendingWithdrawal && !user?.primaryInfo?.isBanned) {
      setShowBonusModal(true);
    }
  }, [user, pendingWithdrawal, hasFetchedPendingWithdrawal]);



  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setAmountWithdrawal(amount);

    if (!isNaN(amount) && amount > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const getBalanceForCurrency = (currency) => {
    const account = accounts.find((acc) => acc.currency === currency);
    return account ? account.balance : 0;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.post('/api/withdrawal', {
        amount: parseFloat(amountwithdrawal),
        currency: selectedCurrency,
        userId: user.primaryInfo?._id,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setWithdrawalResponse(response.data); // Storing the response into state
      setShowSuccess(true);
      setError(null);
    } catch (error) {
      // Handle error
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingWithdrawal = async () => {
    try {
      const response = await api.get('/api/pending-withdrawal', {
        headers: { Authorization: `Bearer ${user.token}` },
        params: { userId: user?.primaryInfo?._id },
      });

      if (response.status === 200) {
        setPendingWithdrawal(response.data.withdrawal);
      }
    } catch (error) {
      console.error("Error fetching pending withdrawal:", error);
    } finally {
      // Always set this to true, regardless of success or failure of API call
      setHasFetchedPendingWithdrawal(true);
    }
  };


  useEffect(() => {
    fetchPendingWithdrawal();
  }, []);

  function generateRandomTransactionNumber() {
    const prefix = "RGP";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = prefix;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  const transID = generateRandomTransactionNumber();

  const generateReceipt = async () => {
    let doc = new jsPDF();
    // Fetch the image, and convert it to Base64 format
    const response = await fetch('https://global.ravelmobile.com/send_paperplane.png');
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    await new Promise(resolve => {
      reader.onloadend = () => {
        resolve();
      };
    });

    // Fetch the barcode image and convert it to Base64 format
    const barcodeResponse = await fetch('https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg');
    const barcodeBlob = await barcodeResponse.blob();
    const barcodeReader = new FileReader();
    barcodeReader.readAsDataURL(barcodeBlob);
    await new Promise(resolve => {
      barcodeReader.onloadend = () => {
        resolve();
      };
    });
    // Set the font styles
    doc.setFont("helvetica", "bold");
    doc.setFontSize(22);
    doc.setTextColor(0, 112, 186);

    doc.text("RAVEL GLOBAL PAY", doc.internal.pageSize.getWidth() / 2, 20, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text("Moving Money for Better", doc.internal.pageSize.getWidth() / 2, 27, {
      align: "center",
    });

    // Add a horizontal line
    doc.setLineWidth(0.5);
    doc.line(20, 35, doc.internal.pageSize.getWidth() - 20, 35);

    // Add paper plane image before the greeting
    const paperPlaneImage = reader.result; // Base64 of the fetched image
    const imgWidth = 40; // choose your desired image width
    const imgHeight = 30; // choose your desired image height
    doc.addImage(paperPlaneImage, 'PNG', doc.internal.pageSize.getWidth() / 2 - imgWidth / 2, 37, imgWidth, imgHeight);

    // Add greeting
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.setTextColor(0, 112, 186);
    doc.text(`Hi ${user?.userInfo?.firstName.toUpperCase()},`, doc.internal.pageSize.getWidth() / 2, 80, {
      align: "center",
    });

    // Set the font styles for receipt details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    // Add blue square with details
    const squareX = 60;
    const squareY = 90;
    const squareSize = 40; // Square size set to 40x40
    doc.setDrawColor(0, 112, 186); // Blue color
    doc.setFillColor(0, 112, 186);
    doc.rect(squareX, squareY, squareSize, squareSize, 'F');

    // Inside square details
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255); // White color for text
    doc.text("Total Amount:", squareX + 5, squareY + 15);


    // Making "Total Amount Paid" text bold by using a different font type
    doc.setFont("helvetica", "bold");
    doc.text("Total Amount:", squareX + 5, squareY + 15);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(`${selectedCurrency} ${amountwithdrawal}`, squareX + 5, squareY + 25);

    // Revert text color to normal outside the square
    doc.setTextColor(0, 0, 0);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.text(`Name: ${user?.userInfo?.firstName.toUpperCase()} ${user?.userInfo?.lastName.toUpperCase()}`, squareX + squareSize + 10, squareY + 5);

    // Add the transaction ID

    doc.text(`Transaction No: ${transID}`, squareX + squareSize + 10, squareY + 15);

    // Add the date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    doc.text(`Transaction Date: ${formattedDate}`, squareX + squareSize + 10, squareY + 25);

    doc.text(`Transaction Type: Withdrawal`, squareX + squareSize + 10, squareY + 35);

    const phoneNumber = user?.primaryInfo?.phoneNumber;
    doc.text(`Phone Number: ${phoneNumber}`, squareX + squareSize + 10, squareY + 45);

    doc.text(`Transaction Status: In Progress`, squareX + squareSize + 10, squareY + 55);

    const barcodeImage = barcodeReader.result; // Base64 of the fetched barcode image
    const barcodeWidth = 160; // choose your desired barcode width
    const barcodeHeight = 30; // choose your desired barcode height
    const pageWidth1 = doc.internal.pageSize.getWidth();
    const barcodeX = (pageWidth1 - barcodeWidth) / 2; // Calculate the x-coordinate to center the barcode
    const barcodeY = squareY + squareSize + 30; // Set the y-coordinate below the square and details
    doc.addImage(barcodeImage, 'PNG', barcodeX, barcodeY, barcodeWidth, barcodeHeight);



    // Add footer line
    doc.setLineWidth(0.5);
    doc.line(20, doc.internal.pageSize.getHeight() - 20, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

    // Footer content
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text("395 OYSTER POINT BLVD STE 500 SOUTH SAN FRANCISCO CA 94080-1933 USA", doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 15, {
      align: "center",
    });

    doc.text("support@ravelmobile.com", doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, {
      align: "center",
    });

    doc.save("Withdraw Receipt.pdf");
  };

  
  return (

    showSuccess ? (
      <div className=" flex items-center justify-center">
        <div className="flex justify-center items-center p-4">
          <div className="bg-white shadow-xl rounded-lg overflow-hidden p-5 space-y-4">

            {/* Success Animation */}
            <Lottie animationData={successAnimation} className="w-48 h-48 mx-auto" style={{ width: '200px', height: '200px' }} />

            <h2 className="text-3xl font-extrabold text-gray-800 text-center">🎉 Successful</h2>
            {/* Transaction Details */}
            <div className="text-left space-y-2">
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Withdraw ID:</span>
                <span>{transID}</span>
              </div>
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Channel:</span>
                <span>Mpesa</span>
              </div>
              {/* Detailed Payment Information */}
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Subtotal:</span>
                <span>{`${amountwithdrawal} ${selectedCurrency}`}</span>
              </div>
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Fees or Discounts:</span>
                <span>0.00</span>
              </div>
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Total Amount:</span>
                <span>{`${amountwithdrawal} ${selectedCurrency}`}</span>
              </div>
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Date:</span>
                <span>{new Date().toLocaleString("en-US", { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</span>
              </div>
            </div>

            {/* Dotted line divider */}
            <div style={{ borderTop: "3px dotted #999", borderRadius: "1px" }} className="my-4"></div>

            {/* Download Receipt Button */}
            <div className="flex justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center"
                onClick={generateReceipt}  // Ensure you have the 'generateReceipt' function defined somewhere
              >
                <i className="fas fa-receipt fa-lg mr-2"></i> {/* This is the receipt icon from Font Awesome */}
                Download Receipt
              </button>
            </div>

            {/* Terms and Conditions or Notes */}
            <div className="text-center mt-4">
              <p className="text-xs text-gray-700">Thank you for your transaction.Keep this receipt for your records.</p>
            </div>

            {/* Optional: Signature Line */}
            <div className="text-center mt-4">
              <p className="text-xs text-gray-700">Authorized Signature: ___________________</p>
            </div>

          </div>
        </div>
      </div>
    ) : (
      <>
        <Navbar />
        {showBonusModal && (
  <>
    <div className="fixed inset-0 bg-gray-500 bg-opacity-30 z-50"></div>
    <div className="fixed inset-0 z-50 flex items-center justify-center p-5">
      <div className="bg-white rounded-lg shadow-xl max-w-md mx-auto" role="document">
        <div className="flex justify-between items-center border-b p-3">
          <h5 className="text-lg font-semibold">Attention!</h5>
          <button type="button" className="text-gray-500 hover:text-gray-800 rounded focus:outline-none" onClick={() => setShowBonusModal(false)}>
            <span className="text-2xl">&times;</span>
          </button>
        </div>
        <div className="p-3">
          <p>Your bonus is about to expire.</p>
        </div>
        <div className="flex justify-end items-center p-3 border-t">
          <Link to="/ravel-mpesa/Withdrawbonus" className="bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-lg text-sm px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2">
            Withdraw
          </Link>
          <button type="button" className="text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 rounded-lg border border-gray-300 text-sm font-medium px-4 py-2" onClick={() => setShowBonusModal(false)}>
            Later
          </button>
        </div>
      </div>
    </div>
  </>
)}


        <section className={styles.pyplWithdrawSection}>
          <div className={styles.mainWithdrawContent}>
            <div className={styles.introHeader}>
              <h1>Withdraw from your Ravel account</h1>
            </div>

            <div className={styles.withdrawWrapperSplitter}>
              <div className={styles.withdrawContainer}>
                {pendingWithdrawal && (
                  <div className="alert alert-info">
                    Your withdrawal request ({pendingWithdrawal.transactionId}) of {pendingWithdrawal.amount} {pendingWithdrawal.currency} will be completed within 1-3 days.
                  </div>


                )}
                <h5>
                  Available balance in your Ravel account:
                  {error && <p className={styles.errorMessage}>{error}</p>}
                  <div className={styles.balanceContainer}>
                    <h5 className={styles.balance}>{getBalanceForCurrency(selectedCurrency)}</h5>
                    <select
                      className={styles.customSelect}
                      name=""
                      id=""
                      value={selectedCurrency}
                      onChange={handleCurrencyChange}
                    >
                      {/* Render the currency options */}
                      {currencies.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </select>
                  </div>
                </h5>
                <p>
                  Please enter the amount you would like to withdraw from your Ravel account:
                  <span className={styles.helpText} onClick={toggleWithdrawalGuide}>
                    Need withdrawal guide <span className={`${styles.circle} ${styles.blink}`}>?</span>
                  </span>
                </p>



                <div className={styles.inputContainer}>
                  <input
                    type="tel"
                    placeholder="Amount"
                    name="amount"
                    id=""
                    value={amountwithdrawal}
                    onChange={handleAmountChange}
                    required
                  />
                  <select name="" id="" className={styles.customSelect}>
                    <option value="USD" selected>
                      {selectedCurrency}
                    </option>
                  </select>
                </div>
                <div className={styles.buttonContainer}>
                  <button disabled={loading || !isValid} onClick={handleSubmit}>
                    {loading ? 'Processing...' : 'Continue to Withdraw'}
                  </button>
                </div>
              </div>
              <div className={styles.svgTransferIllustration}>
                <img src="/ravel-withdraw.png" alt="transfer-illustration" />
              </div>
            </div>
          </div>
          <div className={styles.importantInfo}>
            <p>
              Please note that the maximum amount per transaction is 250,000 KES and you can only hold up to 500,000 KES
              in your M-PESA account. Make sure your M-PESA account can hold your withdrawal balance.
            </p>
          </div>

        </section>


        {showWithdrawalGuide && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{ zIndex: 2147483647 }}>
            <div className="relative bg-white p-8 rounded shadow-xl max-w-xl w-full overflow-y-auto max-h-screen" style={{ maxHeight: '90vh' }}> {/* Modal content with scrolling */}
              <button
                className="absolute top-0 right-0 m-4 text-lg font-semibold focus:outline-none"
                onClick={toggleWithdrawalGuide}
              >
                X
              </button>
              <h3 className="text-xl font-semibold mb-4">Dear valued customer,</h3>

              <p className=" mb-4">
                Thank you for reaching out to us regarding your withdrawal request. We appreciate your business and are here to assist you with the process. Here's a step-by-step guide on how to withdraw money from your account:
              </p>

              <ol className="list-decimal list-inside mb-4 space-y-2">
                <li>
                  <strong className="font-semibold">
                    Activate KES Currency:
                  </strong>
                  <p>
                    To initiate the withdrawal process in Kenyan Shillings (KES), please ensure that you have the KES currency{" "}
                    <Link to="/Currencies" className="text-blue-500 hover:underline">activated in your account</Link>. This activation comes at a cost of $10, which is equivalent to KES 1570. Kindly ensure that the required amount is available in your KES currency account.{" "}
                    <Link to="/ravel-mpesa/deposit" className="text-blue-500 hover:underline">Deposit</Link>.
                  </p>
                </li>
                <li>
                  <strong className="font-semibold">
                    Convert Amount from USD to KES:
                  </strong>
                  <p>
                    Once you have successfully activated the KES currency, the next step is to convert the desired withdrawal amount from US Dollars (USD) to Kenyan Shillings (KES). Our system will use the current exchange rate to calculate the exact equivalent amount in KES.
                  </p>
                </li>
                <li>
                  <strong className="font-semibold">
                    Proceed to Withdraw:
                  </strong>
                  <p>
                    With the converted amount now available in your KES currency account, you are ready to proceed with the withdrawal. You can initiate the withdrawal request through your online banking portal or mobile app, following the regular withdrawal procedure. If you need assistance with the withdrawal process, please visit our{" "}
                    <Link to="/ravel-mpesa/withdraw" className="text-blue-500 hover:underline">withdrawal page</Link>.
                  </p>
                </li>
              </ol>

              <p className="mb-4">
                Should you encounter any difficulties or have further questions during the process, please do not hesitate to contact our{" "}
                <Link to="/contact" className="text-blue-500 hover:underline">customer support team</Link>. We are available 24/7 to assist you and ensure a seamless withdrawal experience.
              </p>

              <p className="mb-4">
                Thank you for choosing our services. We look forward to continuing to serve your financial needs.
              </p>

              <p className="text-base">
                Best regards,
              </p>
            </div>
          </div>
        )}

      </>
    )
  );
};

export default Withdraw;
