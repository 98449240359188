import React, { useState, useEffect } from "react";
import styles from "./Deposit.module.css";
import { Navbar } from "../SoleComponents";
import { useUser } from "../context";
import api from "../../api";
import Lottie from "lottie-react";
import successAnimation from "./success-animation.json";
import { useNavigate, Link } from "react-router-dom";


function generateRandomTransactionNumber() {
  const prefix = "RGP";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = prefix;
  for (let i = 0; i < 5; i++) { // Adjusted loop to generate 5 random characters
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const Deposit = () => {
  const { user, login } = useUser();
  const accounts = user?.accounts;
  const navigate = useNavigate();

  const getUniqueCurrencies = (accounts) => {
    const uniqueCurrencies = Array.from(new Set(accounts.map((account) => account.currency)));
    uniqueCurrencies.sort((a, b) => {
      const balanceA = accounts.find((account) => account.currency === a)?.balance || 0;
      const balanceB = accounts.find((account) => account.currency === b)?.balance || 0;
      return balanceB - balanceA;
    });
    return uniqueCurrencies;
  };

  const currencies = getUniqueCurrencies(accounts);
  const [selectedCurrency, setSelectedCurrency] = useState("KES");
  const [amountDeposit, setAmountDeposit] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [depositResponse, setDepositResponse] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const transID = generateRandomTransactionNumber();
  const [convertedAmount, setConvertedAmount] = useState(0);


  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };


  useEffect(() => {
    if (amountDeposit && selectedCurrency) {
      // Convert the input amount to USD first
      const amountInUSD = amountDeposit * conversionRates['KES'];
      // Then convert the USD amount to the desired currency
      const convertedAmount = amountInUSD / conversionRates[selectedCurrency];

      setConvertedAmount(convertedAmount.toFixed(2));
    } else {
      setConvertedAmount(''); // Clear the converted amount if input amount is falsy or currency is not selected
    }
  }, [amountDeposit, selectedCurrency]);

  // Hardcoded conversion rates
  const conversionRates = {
    USD: 1,
    EUR: 1.09019,
    GBP: 1.24180,
    CAD: 1.351745,
    AUD: 1.30172, // Australian Dollar
    KES: 1 / 157,
    ZAR: 1 / 14.87, // South Africa
    UGX: 1 / 3725, // Uganda
    ZMW: 1 / 19.98, // Zambia
    NGN: 1 / 413.34, // Nigeria
    RWF: 1 / 1010, // Rwanda
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("+")) {
      return phoneNumber.slice(1);
    }
    else if (phoneNumber.startsWith("254")) {
      return phoneNumber;
    } else if (phoneNumber.startsWith("0")) {
      return `254${phoneNumber.slice(1)}`;
    } else if (phoneNumber.startsWith("7") || phoneNumber.startsWith("1")) {
      return `254${phoneNumber}`;
    }
  };

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.post('/api/deposit', {
        amount: parseFloat(amountDeposit),
        currency: selectedCurrency,
        initiatorPhoneNumber: user?.userInfo?.phoneNumber || user?.primaryInfo?.phoneNumber,
        phoneNumber: formattedPhoneNumber,
      });

      if (response.data && response.status === 200) {
        setError(null);
        setSuccessMessage('STK sent, enter the PIN to complete the transaction.');

        setIsPolling(true); // set isPolling to true here

        // Start polling for the deposit status
        const checkoutRequestId = response.data.CheckoutRequestID;
        const pollInterval = 5000; // Check every 5 seconds
        const maxRetries = 12; // Maximum retries (e.g., 12 * 5 seconds = 1 minute)
        let retries = 0;

        const pollDepositStatus = setInterval(async () => {
          try {
            const depositResponse = await api.get(`/api/deposit/${checkoutRequestId}`);
            const deposit = depositResponse.data;
            if (deposit.isSuccess) {
              const balanceResponse = await api.get('/api/getUserBalances', {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                  'user-id': user?.primaryInfo?._id, // Pass the userId as a custom header
                },
              });

              if (balanceResponse.status === 200) {
                // Update the local storage with the new balances
                const updatedUser = { ...user, accounts: balanceResponse.data.accounts };
                localStorage.setItem("user", JSON.stringify(updatedUser));

                // Update the context
                login(updatedUser);
              }
              clearInterval(pollDepositStatus);
              setLoading(false);
              setIsPolling(false);;
              setLoading(false);
              setIsPolling(false);
              setSuccessMessage(null);
              setShowSuccess(true);
              setTimeout(() => {
                setShowSuccess(false);
                navigate('/currencies');
              }, 4000);
            } else if (deposit.error && !deposit.isSuccess) {
              clearInterval(pollDepositStatus);
              setLoading(false);
              setIsPolling(false);
              setError(deposit.error);
              setSuccessMessage(null);
            }

            retries++;
            if (retries >= maxRetries) {
              clearInterval(pollDepositStatus);
              setLoading(false);
              setIsPolling(false); // set isPolling to false here
              setError('Transaction timeout. Please try again.');
              setSuccessMessage(null);
            }
          } catch (error) {
            console.error('Error polling deposit status:', error);
            clearInterval(pollDepositStatus);
            setLoading(false);
            setIsPolling(false); // set isPolling to false here
            setError('Error checking deposit status. Please try again.');
            setSuccessMessage(null);
          }
        }, pollInterval);
      }
    } catch (error) {
      setLoading(false);
      setSuccessMessage(null);
      setError('An error occurred while processing the transaction.Check your connection');
      console.error('Error:', error);
    }
  };

  const handleContinue = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  return (
    showSuccess ? (
      <div className="flex items-center justify-center">
        <div className="flex justify-center items-center p-4">
          <div className="bg-white shadow-xl rounded-lg overflow-hidden p-5 space-y-4">

            {/* Success Animation */}
            <Lottie animationData={successAnimation} className="w-48 h-48 mx-auto" style={{ width: '200px', height: '200px' }} />

            <h2 className="text-3xl font-extrabold text-gray-800 text-center">🎉 Successful</h2>
            {/* Transaction Details */}
            <div className="text-left space-y-2">
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Deposit ID:</span>
                <span>{transID}</span>
              </div>
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Channel:</span>
                <span>Mpesa</span>
              </div>
              {/* Detailed Payment Information */}
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Amount:</span>
                <span>{`${amountDeposit} ${selectedCurrency}`}</span>
              </div>
              <div className="flex justify-between text-xsm text-gray-700">
                <span className="font-semibold">Date:</span>
                <span>{new Date().toLocaleString("en-US", { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</span>
              </div>
            </div>

            {/* Dotted line divider */}
            <div style={{ borderTop: "3px dotted #999", borderRadius: "1px" }} className="my-4"></div>

            {/* Terms and Conditions or Notes */}
            <div className="text-center mt-4">
              <p className="text-xs text-gray-700">Thank you for your deposit. Keep this receipt for your records.</p>
            </div>

            {/* Optional: Signature Line */}
            <div className="text-center mt-4">
              <p className="text-xs text-gray-700">Authorized Signature: ___________________</p>
            </div>

          </div>
        </div>
      </div>
    ) : (
      <>
        <Navbar />
        <div className="max-w-2xl border mx-auto p-4 bg-white  mt-3 rounded-lg">
          {/* Introductory Text */}
          <div className="text-center mb-4">
            <h2 className="text-lg font-semibold text-gray-800">Ravel Deposit </h2>

          </div>
          {error && <p className={styles.errorMessage}>{error}</p>}
          {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
      
            <div className="mb-4">
              <label className="block text-gray-700 text-xsm font-bold mb-2" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter your phone number"
              />
            </div>

            {/* Currency Selection */}
            <div className="mb-4">
              <label className="block text-gray-700 text-xsm font-bold mb-2" htmlFor="currency">
                Currency
              </label>
              <select
                id="currency"
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className='block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded leading-tight focus:outline-none focus:shadow-outline'
              >
                {currencies.map((currency) => (
                  <option key={currency} value={currency}>{currency}</option>
                ))}
              </select>
            </div>

            {/* Amount Input */}
            <div className="mb-6">
              <label className="block text-gray-700 text-xsm font-bold mb-2" htmlFor="amount">
                Amount
              </label>
              <input
                type="text"
                id="amount"
                value={amountDeposit}
                onChange={(e) => setAmountDeposit(e.target.value)}
                className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter amount"
              />
            </div>

            {/* Submit Button */}
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="border border-blue-500 text-blue-500 hover:text-white hover:bg-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out w-full sm:w-auto"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? 'Processing...' : 'Deposit'}
              </button>


            </div>
          {/* Payment Security Section */}
          <div className="mt-4 p-2 bg-gray-100 rounded-lg text-center">
            <div className="flex flex-col justify-center items-center space-y-2 sm:space-y-0">
              {/* Padlock Icon */}
              <svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
              </svg>
              <span className="text-xsm text-gray-700 sm:text-xs">Payment is secured with DLocal</span>
            </div>
            {/* Sensible Address */}
            <div className="mt-2 text-xsm text-gray-600 sm:text-xs">
              Ravel Global Pay, Apt. 992 54072 Larson Stravenue, Port Kymside, IA 70661-2925
            </div>
            {/* Additional Information */}
            <div className="mt-2 text-xs text-gray-500">
              For support: support@ravelmobile.com | Hotline: +1 800 555 0199
            </div>
          </div>


        </div>
      </>

    )
  );

};

export default Deposit;
