// accounts.js

import React, { useState, useEffect } from 'react';
import { Table, Button, Spinner, Modal } from 'react-bootstrap';
import api from '../../api';
import { useParams } from 'react-router-dom';
import "./accounts.Module.css";

const UserAccounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userId } = useParams();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteTargetId, setDeleteTargetId] = useState(null);
    const [buttonLoading, setButtonLoading] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [editTarget, setEditTarget] = useState(null);


    const handleShowEdit = (account) => {
        setEditTarget(account);
        setShowEditModal(true);
    };



    useEffect(() => {
        async function fetchAccounts() {
            try {
                const response = await api.get(`api/accounts/${userId}`);
                setAccounts(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching accounts:", error);
                setLoading(false);
            }
        }
        fetchAccounts();
    }, [userId]);

    if (loading) {
        return (
            <div className="text-center mt-3">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    const handleToggleHeld = async (accountId) => {
        setButtonLoading(prevState => ({ ...prevState, [accountId + 'Held']: true }));
        try {
            const response = await api.patch(`api/accounts/${accountId}/toggle-held`);
            if (response.status === 200) {
                const updatedAccounts = accounts.map(account =>
                    account._id === accountId ? { ...account, isHeld: !account.isHeld } : account
                );
                setAccounts(updatedAccounts);
            } else {
                throw new Error(response.data.message || 'Failed to toggle held status.');
            }
        } catch (error) {
            console.error(`Error toggling held status for account ${accountId}: `, error.message);
        } finally {
            setButtonLoading(prevState => ({ ...prevState, [accountId + 'Held']: false }));
        }
    }


    const handleToggleActive = async (accountId) => {
        setButtonLoading(prevState => ({ ...prevState, [accountId + 'Active']: true }));
        try {
            const response = await api.patch(`api/accounts/${accountId}/toggle-active`);

            if (response.status === 200) {
                const updatedAccounts = accounts.map(account =>
                    account._id === accountId ? { ...account, isActive: !account.isActive } : account
                );
                setAccounts(updatedAccounts);
            } else {
                throw new Error(response.data.message || 'Failed to toggle active status.');
            }

        } catch (error) {
            console.error(`Error toggling active status for account ${accountId}: `, error.message);
        } finally {
            setButtonLoading(prevState => ({ ...prevState, [accountId + 'Active']: false }));
        }
    }


    const handleDelete = async (accountId) => {
        try {
            const response = await api.delete(`api/accounts/${accountId}`);

            if (response.status === 200) {
                const updatedAccounts = accounts.filter(account => account._id !== accountId);
                setAccounts(updatedAccounts);
            } else {
                throw new Error(response.data.message || 'Failed to delete the account.');
            }

        } catch (error) {
            console.error(`Error deleting account ${accountId}: `, error.message);
        }
    }


    const showDeleteConfirmation = (accountId) => {
        setShowDeleteModal(true);
        setDeleteTargetId(accountId);
    }

    const confirmDelete = async () => {
        if (deleteTargetId) {
            await handleDelete(deleteTargetId);
        }
        setShowDeleteModal(false);
        setDeleteTargetId(null);
    }


    const handleEditBalanceSubmit = async (e) => {
        e.preventDefault();
        if (!editTarget) return;

        try {
            const response = await api.patch(`api/accounts/edit-balance`, { id: editTarget._id, balance: editTarget.balance });
            if (response.status === 200) {
                setAccounts(accounts.map(acc =>
                    acc._id === editTarget._id ? { ...acc, balance: editTarget.balance } : acc
                ));
                setShowEditModal(false);
                setEditTarget(null);
            } else {
                throw new Error(response.data.message || 'Failed to update the balance.');
            }
        } catch (error) {
            console.error(`Error updating balance for account ${editTarget._id}: `, error.message);
        }
    };

    return (
        <div className="accounts-container">
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Currency</th>
                        <th>Balance</th>
                        <th>Status</th>
                        <th>Name</th>
                        <th>Held Status</th>
                        <th>Toggle Held</th>
                        <th>Toggle Active</th>
                        <th>Delete</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map((account, idx) => (
                        <tr key={idx}>
                            <td>{account.currency}</td>
                            <td>${account.balance}</td>
                            <td>{account.isBanned ? "Banned" : (account.isActive ? "Active" : "Inactive")}</td>
                            <td>{account.firstName}</td>
                            <td>{account.isHeld ? "Held" : "Not Held"}</td>
                            <td>
                                <Button
                                    variant={account.isHeld ? "secondary" : "warning"}
                                    disabled={buttonLoading[account._id + 'Held']}
                                    onClick={() => handleToggleHeld(account._id)}
                                >
                                    {buttonLoading[account._id + 'Held'] ?
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        : (account.isHeld ? "Release" : "Hold")}
                                </Button>

                            </td>
                            <td>
                                <Button
                                    variant={account.isActive ? "secondary" : "success"}
                                    className="ml-2"
                                    disabled={buttonLoading[account._id + 'Active']}
                                    onClick={() => handleToggleActive(account._id)}
                                >
                                    {buttonLoading[account._id + 'Active'] ?
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        : (account.isActive ? "Deactivate" : "Activate")}
                                </Button>

                            </td>
                            <td>
                                <Button
                                    variant="danger"
                                    className="ml-2"
                                    onClick={() => showDeleteConfirmation(account._id)}>
                                    Delete
                                </Button>
                            </td>
                            <td>
                                <Button variant="primary" onClick={() => handleShowEdit(account)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this account?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Confirm Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Edit Balance</Modal.Title>
    </Modal.Header>
    <form onSubmit={handleEditBalanceSubmit}>
        <Modal.Body>
            <input 
                type="tel" 
                className="form-control" 
                value={editTarget ? editTarget.balance : ''}
                onChange={(e) => setEditTarget({...editTarget, balance: e.target.value})} 
                placeholder="Enter new balance" 
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Close
            </Button>
            <Button type="submit" variant="primary">Update Balance</Button>
        </Modal.Footer>
    </form>
</Modal>


        </div>
    );
}
export default UserAccounts;
