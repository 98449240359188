import React from "react";
import styles from "./SettingsHeader.module.css";
import { useSwipeable } from "react-swipeable";
import { useNavigate, useMatch } from "react-router-dom";

function SettingsHeader() {
  const navigate = useNavigate();
  const match = useMatch("/ravel-mpesa/:subPage");
  const activeSubPage = match?.params?.subPage;

  const handleLinkClick = (subPage) => {
    navigate(`/ravel-mpesa/${subPage}`);
  };

  const handleSwipe = (eventData) => {
    if (eventData.dir === "Left") {
      // Handle swipe left logic
    } else if (eventData.dir === "Right") {
      // Handle swipe right logic
    }
  };

  const swipeHandlers = useSwipeable({ onSwiped: handleSwipe });

  return (
    <div className={styles.settingsNav} {...swipeHandlers}>
      <div className={styles.scrollContainer}>
        <div
          onClick={() => handleLinkClick("deposit")}
          className={activeSubPage === "deposit" ? styles.active : styles.account}
        >
          Deposit
        </div>
        <div
          onClick={() => handleLinkClick("withdraw")}
          className={activeSubPage === "withdraw" ? styles.active : styles.account}
        >
          Withdraw
        </div>
        <div
          onClick={() => handleLinkClick("convert")}
          className={activeSubPage === "convert" ? styles.active : styles.account}
        >
          Convert
        </div>
        <div
          onClick={() => handleLinkClick("Withdrawbonus")}
          className={activeSubPage === "Withdrawbonus" ? styles.active : styles.account}
        >
          Bonus
        </div>
        <div
          onClick={() => handleLinkClick("transaction-history")}
          className={activeSubPage === "transaction-history" ? styles.active : styles.account}
        >
          History
        </div>
      </div>
      <div className={styles.pulseArrow}>→</div>
    </div>
  );
}

export default SettingsHeader;
