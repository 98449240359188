import React, { useState } from "react";
import styles from "./BonusTransfer.module.css";
import { Navbar } from "../SoleComponents";
import { useUser } from "../context";
import api from "../../api";
import Lottie from "lottie-react";
import successAnimation from "./success-animation.json";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // Import the ClipLoader spinner

function BonusTransfer() {
  const { user, login } = useUser();
  const accounts = user?.accounts;
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [recipientPayID, setRecipientPayID] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValidForm = amount > 0 && amount <= 200 && recipientPayID.trim() !== '';

  const handleTransfer = async (e) => {
    e.preventDefault();

    if (!isValidForm || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (user.primaryInfo?.payID !== 701719) {
      setMessage('You are not authorized to perform this action.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post('/api/sendBonus', {
        amount,
        senderUserId: user.primaryInfo?._id,
        senderPayID: user.primaryInfo?.payID,
        recipientPayID,
      });

      setMessage(response.data.message);
    } catch (error) {
      setMessage((error.response && error.response.data.message) || 'Error occurred while sending bonus.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      {message && (
        <p className={message.startsWith("Error") ? styles.errorMessage : styles.successMessage}>
          {message}
        </p>
      )}
     <form onSubmit={handleTransfer} className={styles.bonusForm}>
        <label className={styles.inputLabel}>
          Amount:
          <input
            type="tel"
            className={styles.textInput}
            value={amount}
            onChange={(e) => setAmount(Math.min(Number(e.target.value), 200))}
            max="200"
          />
        </label>

        <label className={styles.inputLabel}>
          Recipient's PayID:
          <input
            type="text"
            className={styles.textInput}
            value={recipientPayID}
            onChange={(e) => setRecipientPayID(e.target.value)}
          />
        </label>
        <button
          type="submit"
          className={`${styles.submitButton} ${!isValidForm || isSubmitting ? styles.disabledButton : ''}`}
        >
          {isSubmitting ? <ClipLoader color="#ffffff" loading={isSubmitting} size={20} /> : 'Send Bonus'}
        </button>
      </form>
    </div>
  );
}

export default BonusTransfer;
