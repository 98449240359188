import React from "react";
import { Link } from "react-router-dom";
import styles from "./Account.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import { useUser } from "../context";

const DropdownIndicator = (props) => {
  const iconStyle = {
    fontSize: "1.5rem",
  };

  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <span style={iconStyle}>
          <MdExpandLess />
        </span>
      ) : (
        <span style={iconStyle}>
          <MdExpandMore />
        </span>
      )}
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, label, ...props }) => (
  <components.SingleValue {...props}>
    <div className={styles.selectLabelContainer}>
      <span className={styles.selectLabel}>{label}</span>
      <span className={styles.selectSelectedValue}>{children}</span>
    </div>
  </components.SingleValue>
);

const customStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    borderLeft: "none",
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "transparent" : base.backgroundColor,
    fontWeight: isSelected ? "bold" : base.fontWeight,
    color: isSelected ? "black" : base.color,
  }),
};

const Option = (props) => {
  const { children, isSelected, ...rest } = props;
  return (
    <components.Option {...rest}>
      <div className={styles.selectOptionContent}>
        {children}
        {isSelected && <IoCheckmark className={styles.selectOptionCheckmark} />}
      </div>
    </components.Option>
  );
};

function Account() {
  const { user, logout } = useUser();
  const languageOptions = [
    { value: "English", label: "English" },
    { value: "Francais", label: "Francais" },
    { value: "Espanol", label: "Espanol" },
  ];

  const zoneOptions = [
    { value: "(GMT-08:00) Pacific Time", label: "(GMT-08:00) Pacific Time" },
  ];

  const handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
  };

  return (
    <div className="font-sans bg-gray-100 px-1 py-3 sm:px-4 sm:py-5 md:space-x-5 md:flex md:justify-center lg:px-20">
    <div className="font-sans bg-gray-100 px-1 py-3 sm:px-4 sm:py-5 md:space-x-5 md:flex md:justify-center lg:px-20">
      <div className="bg-white shadow rounded-lg w-full max-w-2xl mx-auto overflow-hidden">
        {/* Profile header */}
        <div className="bg-blue-600 text-white p-4 flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
          <h1 className="font-bold text-2xl md:text-3xl">Profile</h1>
          <p className="font-semibold text-sm md:text-base text-center md:text-left">
            Joined{" "}
            {new Date(user?.userInfo?.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center p-4">
          <div className="flex items-center space-x-4">
            <div className="rounded-full bg-gray-200 text-gray-700 w-10 h-10 flex justify-center items-center font-bold text-xl">
              {user?.userInfo?.firstName[0].toUpperCase()}
            </div>
            <p className="font-semibold text-lg md:text-xl">{`${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`}</p>
          </div>
          <Link className="text-blue-600 hover:text-blue-800 font-semibold underline mt-2 md:mt-0 transition duration-300 ease-in-out">Change name</Link>
        </div>

        <div className="bg-white border rounded-lg p-6">
          <p className={styles.options}>Account Options</p>
          <Select
            defaultValue={languageOptions[0]}
            options={languageOptions}
            components={{
              DropdownIndicator,
              SingleValue: (props) => (
                <SingleValue {...props} label="Language" />
              ),
              Option,
            }}
            onChange={handleChange}
            className={styles.selectOptions}
            styles={customStyles}
          />
          <Select
            defaultValue={zoneOptions[0]}
            options={zoneOptions}
            components={{
              DropdownIndicator,
              SingleValue: (props) => (
                <SingleValue {...props} label="Time Zone" />
              ),
              Option,
            }}
            onChange={handleChange}
            className={styles.selectOptions}
            styles={customStyles}
          />
          <div className="bg-white shadow-sm rounded-lg p-4 mb-6">
            {/* User Information */}
            <div className="space-y-4">
              <div>
                <p className="text-sm font-semibold text-gray-600">Nationality</p>
                <p className="text-lg font-small text-gray-800">Kenya</p>
              </div>
              <div>
                <p className="text-sm font-semibold text-gray-600">Merchant ID</p>
                <p className="text-lg font-small text-gray-800">LV3C2EXEWPYCJ</p>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-sm font-semibold text-gray-600">National ID</p>
                  <p className="text-lg font-small  text-gray-800">{user?.userInfo?.idNumber}</p>
                </div>
                <Link className="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out">Edit</Link>
              </div>
            </div>

            <Link className="mt-4 inline-block text-blue-600 hover:text-indigo-700 transition duration-150 ease-in-out">Add Passport</Link>
          </div>

          {/* Features and Account Management */}
          <div className="mt-4 space-y-4">
            <div className="flex items-center p-4 border rounded-md hover:shadow-md transition duration-300 ease-in-out">
            <div className="flex justify-center items-center rounded-full bg-blue-100 text-blue-500 w-10 h-10 mr-2 ">
                <FontAwesomeIcon icon={faShieldAlt} className="h-5 w-5" />
              </div>
              <div>
                <p className="font-semibold text-gray-900">Unlock new features like express checkout</p>
                <Link className="text-blue-600 hover:text-blue-700 font-semibold underline transition duration-300 ease-in-out">Upgrade to a Business account</Link>
              </div>
            </div>

            <div className="flex items-center p-4 border rounded-md hover:shadow-md transition duration-300 ease-in-out">
            <div className="flex justify-center items-center rounded-full bg-red-100 text-red-500 w-10 h-10 mr-2 ">
  <FontAwesomeIcon icon={faUserCircle} className="h-5 w-5" />
</div>

              <p className="font-semibold text-gray-900">Close your account</p>
            </div>
          </div>
        </div>
        </div>
     
      
      <div className="bg-white shadow rounded-lg p-4 mt-0 sm:mt-4 md:w-1/2">
<div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 hover:shadow-lg transition-shadow duration-200 ease-in-out">
          <div className="border-b border-gray-200 pb-4 mb-4">
            <div className="flex justify-between items-center">
              <p className="text-lg font-semibold">Email</p>
              <Link className="text-blue-600 text-sm hover:text-blue-700 underline transition duration-150 ease-in-out">Add</Link>
            </div>
            <div className="mt-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className="flex items-center space-x-2">
                <p className="font-medium break-words">
                  {user?.userInfo?.email}
                  <span className="bg-blue-100 text-blue-600 py-0.5 px-2 ml-2 rounded-full text-xs inline-block">Primary</span>
                </p>
              </div>
              <Link className="text-blue-600 text-sm hover:text-blue-700 underline mt-2 sm:mt-0 transition duration-150 ease-in-out self-end sm:self-auto">Change</Link>
            </div>
          </div>
          <p className="text-gray-500 text-sm mt-4">
            To update an email address, you must have at least two email addresses on file.
          </p>
        </div>


        <div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 hover:shadow-lg transition-shadow duration-200 ease-in-out">
          {/* Phone Numbers Section */}
          <div className="border-b border-gray-200 pb-4 mb-4">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold">Phone Numbers</p>
              <Link className="text-blue-600 text-sm hover:text-blue-700 underline transition duration-150 ease-in-out">Add</Link>
            </div>
            <div className="mt-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className="flex items-center space-x-2">
                <p className="font-medium break-words">
                  {user?.userInfo?.phoneNumber}
                  <span className="bg-blue-100 text-blue-600 py-0.5 px-2 ml-2 rounded-full text-xs inline-block">Primary</span>
                </p>
              </div>
              <Link className="text-blue-600 text-sm hover:text-blue-700 underline mt-2 sm:mt-0 transition duration-150 ease-in-out self-end sm:self-auto">Change</Link>
            </div>
          </div>
        </div>


        <div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 hover:shadow-lg transition-shadow duration-200 ease-in-out">
          {/* Addresses Section */}
          <div className="pt-4">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold">Addresses</p>
              <Link className="text-blue-600 text-sm hover:text-blue-700 underline transition duration-150 ease-in-out">Add</Link>
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                <p className="font-medium break-words">
                  {user?.userInfo?.poBox} {user?.userInfo?.town}, {user?.userInfo?.city}, {user?.userInfo?.Country}
                  <span className="bg-blue-100 text-blue-600 py-0.5 px-2 ml-2 rounded-full text-xs inline-block">Primary</span>
                </p>
              </div>
              <Link className="text-blue-600 text-sm hover:text-blue-700 underline mt-2 sm:mt-0 transition duration-150 ease-in-out self-end sm:self-auto">Edit</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Account;
