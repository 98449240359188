import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { showToast } from "../utils/showToast";
import { useUser } from "./context";
import api from '../api';
import { FaSpinner } from 'react-icons/fa';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith("+")) {
    return phoneNumber.slice(1);
  }
  else if (phoneNumber.startsWith("254")) {
    return phoneNumber;
  } else if (phoneNumber.startsWith("0")) {
    return `254${phoneNumber.slice(1)}`;
  } else if (phoneNumber.startsWith("7") || phoneNumber.startsWith("1")) {
    return `254${phoneNumber}`;
  }
};

const getFingerprint = async () => {
  try {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
  } catch (error) {
    console.error("Error obtaining fingerprint:", error);
    // Handle the error as per your application's needs
    // For example, you can return a default value or null
    return null;
  }
};

function Login() {
  const [loading, setLoading] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login, logout } = useUser();

  useEffect(() => {
    logout();
  }, [logout]);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!navigator.onLine) {
      showToast("warning", "No internet connection");
      return;
    }

    let type = "email";
    let credentials = {};

    if (identifier.includes("@")) {
      credentials = { email: identifier, password };
    } else {
      type = "phone";
      credentials = { phoneNumber: formatPhoneNumber(identifier), password };
    }

    setLoading(true);
    try {
      const fingerprintId = await getFingerprint();
  
      const loginCredentials = {
        ...credentials,
        fingerprintId,
      };
  
      const response = await api.post("/api/login", loginCredentials);
      const data = response.data;

      if (response.status === 200) {
        logout();
        if (data.isVerified) {
          if (data.hasCompletedKYC) {
            showToast("success", "Logged in successfully");
            login({
              email: data.primaryInfo.email,
              phoneNumber: data.primaryInfo.phoneNumber,
              isVerified: data.isVerified,
              userId: data.userId,
              payID: data.primaryInfo.payID,
              userInfo: data.userInfo,
              accounts: data.accounts,
              token: data.token,
              primaryInfo: data.primaryInfo,
            });

            localStorage.setItem("user", JSON.stringify(data));

            // Check if the user has filled in the PIN
            if (!data.userInfo.pin) {
              navigate("/pin");
            } else {
              navigate("/home");
            }
          } else {
            showToast("warning", "Please complete the KYC process");
            navigate("/KYC", { state: { userId: data.userId } });
          }
        } else {
          showToast("warning", "User not verified");
          navigate("/verify", {
            state: {
              mode: type,
              contact: identifier,
              userId: data.userId,
            },
          });
        }
      } else {
        const errorMessage = data.message || "Login failed";
        if (response.status === 400 && data.errors) {
          const errorFields = Object.keys(data.errors).join(", ");
          showToast("error", `${errorMessage}: ${errorFields}`);
        } else {
          showToast("error", errorMessage);
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        showToast("error", error.response.data.message);
      } else {
        showToast("error", "Error on login, check your Internet connection");

      }
    } finally {
      setLoading(false);
    }
  };




  return (
    <div className="min-h-auto flex flex-col justify-center  py-2 sm:px-6 lg:px-2">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ToastContainer />
        <div className="flex flex-col items-center justify-center">
  <img 
    src='/2095947_3bd8f.gif'
    alt="Balloons" 
    className="w-16 h-16 mb-2"
  />
  <h2 className="text-xl font-extrabold text-gray-900">
    Welcome back!
  </h2>
</div>

        <p className="mt-2 text-center text-xsm text-gray-600 max-w"> Log in with your Email or Phone number</p>
        <p className="mt-2 text-center text-xsm text-gray-600 max-w">
          Or{' '}
          <Link to="/signup" className="font-medium text-primary hover:text-primary-dark transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            create a new account
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleLogin}>
            <div>
              <label htmlFor="identifier" className="sr-only">Email or Phone number</label>
              <input
                id="identifier"
                name="identifier"
                type="text"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-xsm"
                placeholder="Email or Phone number"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-xsm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div>
              <button
                type="submit"
                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-xsm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition duration-150 ease-in-out ${loading ? 'opacity-50' : ''
                  }`}
                disabled={loading}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {loading ? (
                    <FaSpinner className="h-5 w-5 text-white-500 group-hover:text-white-400 animate-spin" />
                  ) : (
                    <svg className="h-5 w-5 text-white-500 group-hover:text-white-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                    </svg>
                  )}
                </span>
                {loading ? 'Logging in...' : 'Log in'}
              </button>

            </div>
          </form>
          <div className="mt-6">
            <div className="text-xsm text-center">
              <Link to="/forgot-password" className="font-medium text-primary hover:text-primary-dark transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                Forgot your password?
              </Link>
            </div>
          </div>
        </div>
{/* Secure Transaction Info Message */}
<div className="mt-6 px-3 sm:px-5 py-2 sm:py-3 bg-green-100 border-green-500 rounded-lg shadow-lg">
  <div className="flex items-center">
    {/* Shield Icon */}
    <img src="/protection-shield-icon.png" alt="Secure" className="h-5 sm:h-6 w-5 sm:w-6 text-green-600" />
    <div className="ml-3 sm:ml-4">
      <p className="text-xs sm:text-xsm font-semibold text-green-800">
        Transaction Security
      </p>
      <p className="text-xs sm:text-xsm text-green-800 mt-1">
        We ensure your transactions are safe with state-of-the-art encryption and security measures.
      </p>
    </div>
  </div>
</div>



      </div>
      
    </div>
  );
}
export default Login;