import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';
import api from '../api';
import '../App.css';
import { showToast } from "../utils/showToast";

const formatPhoneNumber = (phoneNumber) => {
  // Check for expected formats and modify accordingly
  if (phoneNumber.startsWith("+")) {
    return phoneNumber.slice(1); // remove the '+' prefix
  }
  else if (phoneNumber.startsWith("254")) {
    return phoneNumber; // format is already correct
  } else if (phoneNumber.startsWith("0")) {
    return `254${phoneNumber.slice(1)}`; // replace leading 0 with country code
  } else if (phoneNumber.startsWith("7") || phoneNumber.startsWith("1")) {
    return `254${phoneNumber}`; // add country code prefix
  } else {
    // If format is unexpected, return the original number
    // Optionally, you could add logging here or some other form of notification
    // that an unexpected format was encountered.
    return phoneNumber;
  }
};



function Signup() {
  const [loading, setLoading] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralId, setReferralId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showReferralField, setShowReferralField] = useState(false);
  const navigate = useNavigate();

  const handleIdentifierInputChange = (event) => {
    setIdentifier(event.target.value);
  };

  const handlePasswordInputChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordInputChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleReferralIdInputChange = (e) => {
    setReferralId(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleReferralClick = () => {
    setShowReferralField(!showReferralField);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!navigator.onLine) {
      showToast("warning", "No internet connection");
      return;
    }

    let isEmail = identifier.includes("@");
    let formattedIdentifier = identifier;

    if (!isEmail) {
      formattedIdentifier = formatPhoneNumber(identifier);
    }

    const formData = isEmail
      ? { email: identifier, password, referralId }
      : { phoneNumber: formattedIdentifier, password, referralId };
    const route = isEmail ? "/api/signup" : "/api/signupWithMobile";

    try {
      const response = await api.post(route, formData);

      if (response.status === 200) {
        showToast('success', "User registered successfully. Sending verification code...");

        const verificationType = isEmail ? "email" : "phoneNumber";
        const contact = formattedIdentifier;

        const resendResponse = await api.post("/api/verification", {
          [verificationType]: contact
        });

        if (resendResponse.status === 200) {
          showToast('success', "Verification code sent successfully!");
        } else {
          const errorMessage = resendResponse.data.message || "Error sending verification code";
          showToast('error', errorMessage);
        }

        navigate("/verify", {
          state: {
            mode: verificationType,
            contact: contact,
          },
        });

      } else {
        const errorMessage = response.data.message || "Registration failed";
        showToast('error', errorMessage);
      }

    }
    catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        showToast("error", error.response.data.message);
      } else {
        showToast("error", "Error on Registration check your Internet");
        console.error("Error on login: ", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center  bg-light py-8 px-1 sm:px-1 lg:px-8">
      <div className="max-w-md w-full space-y-6">
        <ToastContainer />
        <div className="flex flex-col items-center justify-center">
  <img 
    src='/balloons-1.png'
    alt="Balloons" 
    className="w-16 h-16 mb-2" 
  />
</div>
        <div className="text-center">
          <h2 className="text-2xl font-extrabold text-gray-900">Register your account</h2>
          <p className="mt-2 text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-primary hover:text-primary-dark">
              Log In
            </Link>
          </p>
        </div>
        <div className="mt-8 bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="sr-only">Email or Mobile number</label>
              <input
                type="text"
                id="email"
                name="email"
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Email or Mobile number"
                value={identifier}
                onChange={handleIdentifierInputChange}
                required
              />
            </div>
            
            {/* Password input with toggle feature */}
            <div className="relative">
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={handlePasswordInputChange}
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button type="button" className="text-gray-500" onClick={toggleShowPassword}>
                  {showPassword ? (
                    <i className="fas fa-eye-slash" aria-hidden="true"></i>
                  ) : (
                    <i className="fas fa-eye" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </div>
  
            {/* Password confirmation field */}
            <div>
              <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                className={`block w-full px-3 py-2 border ${password !== confirmPassword && confirmPassword !== '' ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordInputChange}
                required
              />
              {password !== confirmPassword && confirmPassword !== '' && (
                <p className="mt-2 text-red-500 text-xs">Passwords do not match.</p>
              )}
            </div>
  
            {/* Optional referral field */}
            <div>
              <button
                type="button"
                className="text-left text-sm font-medium text-primary hover:text-primary-dark focus:outline-none"
                onClick={handleReferralClick}
              >
                {showReferralField ? 'Hide Referral' : 'Have a Referral?'}
              </button>
              {showReferralField && (
                <input
                  type="text"
                  className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  placeholder="Referral ID"
                  value={referralId}
                  onChange={handleReferralIdInputChange}
                />
              )}
            </div>
  
            {/* Submit button */}
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                disabled={loading}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  "Create Account"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
                }
  export default Signup;