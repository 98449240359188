import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FaEllipsisV, FaMobileAlt, FaInfoCircle, FaUtensils, FaShoppingBag, FaWater, FaMoneyBillWave, FaLandmark, FaGift } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { useUser } from "./context";
import api from '../api';
import './home.css';
import { ToastContainer, toast, Slide } from 'react-toastify';


const Dashboard = () => {

  const [showPopup, setShowPopup] = useState(false);
  const { user, login } = useUser();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [pendingWithdrawal, setPendingWithdrawal] = useState(null);
  // Set a default value for currentStep
  let currentStep = 1;
  // State to handle the visibility of the "copied" message.
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // Set 'copied' to true when the text has been successfully copied
        setCopied(true);
        toast.success('Successfully copied to clipboard!');

        // Optional: Reset the 'copied' state after a few seconds to hide the "copied" message.
        setTimeout(() => {
          setCopied(false);
        }, 3000); // resets back to false after 3 seconds
      })
      .catch(err => {
        console.error('Could not copy text to clipboard: ', err);
        // Optionally, you could set up another piece of state to handle and display copy errors.
      });
  };


  if (pendingWithdrawal) {
    try {
      // Parse the createdAt date string into a JavaScript Date object
      const createdAt = new Date(pendingWithdrawal.createdAt);

      // Calculate the difference in hours between the current time and createdAt
      const elapsedHours = (Date.now() - createdAt.getTime()) / (1000 * 60 * 60);

      // Determine the current step based on elapsed hours
      if (elapsedHours < 12) {
        currentStep = 1;
      } else if (elapsedHours >= 12 && elapsedHours < 48) {
        currentStep = 2;
      } else {
        currentStep = 3;
      }
    } catch (error) {
      console.error("Error determining current step:", error);
    }
  }



  const fetchTransactions = useCallback(async () => {
    try {
      const transactionResponse = await api.get('/api/transactions', {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          'user-id': user?.primaryInfo?._id,
        },
      });

      if (transactionResponse.status === 200) {
        setTransactions(transactionResponse.data);
      }
    } catch (error) {
      setError('Failed to fetch transactions');
      console.error('Failed to fetch transactions:', error);
    }
  }, [user?.token, user?.primaryInfo?._id]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const fetchBalance = useCallback(async () => {
    try {
      const balanceResponse = await api.get('/api/getUserBalances', {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          'user-id': user?.primaryInfo?._id,
        },
      });

      if (balanceResponse.status === 200) {
        const { accounts, userInfo, primaryInfo } = balanceResponse.data;
        const updatedUser = {
          ...user,
          accounts: accounts,
          userInfo: userInfo,
          primaryInfo: primaryInfo,
        };
        login(updatedUser);
      }
    } catch (error) {
      setError('Failed to fetch balance and user information');
      console.error('Failed to fetch balance and user information:', error);
    }
  }, [user?.token, user?.primaryInfo?._id, login]);


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchBalance();
      }
    };

    if (document.visibilityState === 'visible') {
      fetchBalance();
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);



  useEffect(() => {
    if (user && !user.userInfo?.pin) {
      navigate("/pin");
    }
  }, [user, navigate]);

  const fetchPendingWithdrawal = async () => {
    try {
      const response = await api.get('/api/pending-withdrawal', {
        headers: { Authorization: `Bearer ${user.token}` }, // Pass the user token
        params: { userId: user?.primaryInfo?._id }, // Pass the userId in the params
      });

      if (response.status === 200) {
        setPendingWithdrawal(response.data.withdrawal);
      }
    } catch (error) {
      console.error("Error fetching pending withdrawal:", error?.response.data.message);
    }
  };

  useEffect(() => {
    fetchPendingWithdrawal();
  }, []);

  const accounts = user?.accounts || [];
  const anyAccountHeld = accounts.some(account => account.isHeld);
  let primaryAccount = accounts.find(account => account.isPrimary);
  let highestBalanceAccount = accounts.reduce((acc, curr) => (curr.balance > acc.balance ? curr : acc), accounts[0] || {});
  let accountStatus = "";

  if (highestBalanceAccount?.isBanned) {
    accountStatus = "banned";
  } else if (highestBalanceAccount?.isActive) {
    accountStatus = "active";
  } else {
    accountStatus = "inactive";
  }

  if (!highestBalanceAccount || primaryAccount?.balance === highestBalanceAccount?.balance) {
    highestBalanceAccount = primaryAccount;
  }


  const getCurrencySymbol = (currency) => {
    const currencySymbols = {
      USD: "$",
      GBP: "£",
      AUD: "A$",
      CAD: "C$",
      EUR: "€",
      ZAR: "R",
      KES: "KSh",
      UGX: "USh",
      ZMW: "ZK",
      NGN: "₦",
      RWF: "FRw",
    };

    return currencySymbols[currency] || currency;
  };



  // Quick Actions with icons mapping
  const quickActions = [
    { name: 'Buy Airtime', icon: <FaMobileAlt /> },
    { name: 'Order Food', icon: <FaUtensils /> },
    { name: 'Buy Goods', icon: <FaShoppingBag /> },
    { name: 'Transfer Money', icon: <FaMoneyBillWave /> },
    { name: 'Pay Loan', icon: <FaLandmark /> },
    { name: 'Gift Card', icon: <FaGift /> }
  ];

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          toastClassName="custom-toast"
        />
      </div>

      <div className="max-w-7xl mx-auto px-2 sm:px-3 lg:px-8 pt-10">
        {
          user?.primaryInfo?.isBanned ? (
            <div className="bg-red-600 text-white font-semibold rounded-lg border border-red-700 p-3 mb-3 shadow-md flex items-center space-x-3" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <div className="flex-1">
                <p className="text-xsm sm:text-base">
                  Attention: Your account has been permanently limited.<br /> You can no longer use Ravel as we've decided to permanently limit your account after a review.
                </p>
              </div>
            </div>
          ) : anyAccountHeld && (
            <div className="bg-yellow-600 text-white font-semibold rounded-lg border border-yellow-700 p-3 mb-3 shadow-md flex items-center space-x-3" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <div className="flex-1">
                <p className="text-xsm font-semibold  sm:text-base">
                  Attention: Your account is temporarily on hold. Please check your email for required documents or contact our support team at support@ravelmobile.com for more information.
                </p>
              </div>
            </div>
          )
        }
        {pendingWithdrawal && (
          <div className="mb-3 text-xsm">
            <div className={`p-4 border ${currentStep === 1 ? 'border-blue-500' : 'border-gray-300'} rounded shadow`}>

              {anyAccountHeld ? (
                <p className="text-gray-400">
                  Paused: We are almost there! Just provide the required documents, and we'll swiftly process your transaction. Hang in there mate!
                </p>
              ) : (
                <>
                  {currentStep === 1 && <p className="text-blue-500 mb-1">Transaction {pendingWithdrawal.transactionId} is currently with the Compliance Check department. Hang tight!</p>}
                  {currentStep === 4 && <p className="text-yellow-500 mb-1">Transaction {pendingWithdrawal.transactionId} has moved to the Verification department. You're one step closer!</p>}
                  {currentStep === 3 && <p className="text-blue-500 mb-1">Transaction {pendingWithdrawal.transactionId} is under Approval. Almost there!</p>}
                  {currentStep === 2 && <p className="text-green-500 mb-1">Transaction {pendingWithdrawal.transactionId} is being disbursed. Funds will be in your account soon!</p>}
                </>
              )}

              <div className="relative pt-1">
                <div className="overflow-hidden h-2 mb-2 text-xs flex rounded bg-gray-200">
                  <div
                    style={{ width: `${anyAccountHeld ? '75%' : `${currentStep * 25}%`}` }}
                    className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center 
                        ${anyAccountHeld ? 'bg-blue-500' :
                        currentStep === 1 ? 'bg-blue-500' :
                          currentStep === 2 ? 'bg-yellow-500' :
                            currentStep === 3 ? 'bg-blue-500' :
                              'bg-green-500'
                      }`}
                    role="progressbar"
                    aria-valuenow={anyAccountHeld ? 75 : currentStep * 25}
                    aria-valuemin="0"
                    aria-valuemax="100">
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}

        <div className="grid lg:grid-cols-2 gap-6">
          {/* Enhanced Supreme Balance Section */}
          <section className="mb-8 space-y-6">
            {/* Main balance section */}
            <div className="p-6 bg-white rounded-lg shadow-md transition-shadow hover:shadow-lg background-opacity" style={{ position: 'relative' }}>
              <div
                className="flex justify-between items-center mb-4"

              >
                <h2 className="text-xl font-semibold text-gray-800">
                  {user ? `Hello, ${user?.userInfo?.firstName}` : "..."}
                </h2>
                <div
                  className="inline-flex items-center justify-center p-1 bg-gray-200 rounded-full h-8 w-8 cursor-pointer hover:bg-yellow-300 transition-colors"
                  onClick={() => setShowPopup(!showPopup)}
                  style={{ border: "2px dotted white", boxSizing: "border-box" }}
                >
                  <FaEllipsisV className="text-white" />
                </div>
              </div>

              {highestBalanceAccount && (
                <div
                  className={`text-xl font-bold my-1 mb-3 ${highestBalanceAccount.isHeld ? "text-red-500" : "text-gray-900"}`}
                >
                  {getCurrencySymbol(highestBalanceAccount.currency)} {Math.floor(highestBalanceAccount.balance)}
                </div>
              )}


              {/* Status and Pay ID section */}
              <div className="pt-4 border-t-2 border-dotted border-gray-300">
                <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0">
                  <div className="flex items-center sm:block">
                    <span className="text-gray-600 text-sm">Status:</span>
                    {accountStatus === "active" && (
                      <Link to="/Currencies">
                        <span className="ml-2 sm:ml-0 bg-green-200 text-green-800 py-1 px-3 rounded-full text-xs font-medium">Active</span>
                      </Link>
                    )}
                    {accountStatus === "inactive" && (
                      <Link to="/Currencies">
                        <span className="ml-2 sm:ml-0 bg-yellow-200 text-yellow-800 py-1 px-3 rounded-full text-xs font-medium">Inactive</span>
                      </Link>
                    )}
                    {accountStatus === "banned" && (
                      <Link to="/Currencies">
                        <span className="ml-2 sm:ml-0 bg-red-200 text-red-800 py-1 px-3 rounded-full text-xs font-medium">Banned</span>
                      </Link>
                    )}
                  </div>
                  <div className="flex items-center sm:block">
                    <span className="text-gray-600 text-sm">Pay ID:</span>
                    <div className="flex items-center ml-2 sm:ml-0">
                      <span className="text-gray-900 font-medium mr-2">{user?.primaryInfo?.payID}</span>
                      <MdContentCopy
                        onClick={() => copyToClipboard(user?.primaryInfo?.payID)} // pass the user's payID here
                        className="cursor-pointer text-gray-400 hover:text-gray-600 transition-colors"
                        size={20}
                      />
                      {copied && <span className="text-green-500 text-xs ml-2">Copied!</span>}
                    </div>

                  </div>
                </div>
              </div>
            </div>


            {/* Main card */}
            <div className="main-ticket bg-white rounded-lg shadow-md p-3 sm:p-3 mb-4 sm:mb-3">
              {/* Grid layout with 2 columns for all screen sizes */}
              <div className="grid grid-cols-2 gap-4 sm:gap-6">

                {/* Bonus Section */}
                <div className="ticket-section p-3 sm:p-3 border-2 border-dashed border-green-200 rounded-lg text-center hover:shadow-lg transition-shadow duration-300 ease-in-out">
                  <div className="mb-3 sm:mb-4">
                    {/* Icon or image can go here */}
                  </div>
                  <h3 className="text-md sm:text-lg font-semibold">
                    Bonus:<span className="hotspot-indicator ml-2"></span>
                  </h3>
                  <div className="text-xs sm:text-sm font-medium text-gray-900 my-2">
                    Ksh: {user?.primaryInfo?.bonus}
                  </div>
                </div>

                {/* KES Balance Section */}
                {accounts
                  .filter(account => ['KES'].includes(account.currency))
                  .map(account => (
                    <div key={account.id} className="ticket-section p-3 sm:p-3 border-2 border-dashed border-blue-200 rounded-lg text-center hover:shadow-lg transition-shadow duration-300 ease-in-out">
                      <div className="mb-3 sm:mb-4">
                        {/* Icon or image can go here */}
                      </div>
                      <h3 className="text-md sm:text-lg font-semibold">{account.currency}</h3>
                      <div className="text-xs sm:text-sm font-semibold text-gray-900 my-2">
                        {getCurrencySymbol(account.currency)} {account.balance}
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
          </section>

          {/* Expanded Quick Actions Section */}
          <section className="mb-6 p-1">
            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-3 gap-4"> {/* Adjusted for better spacing and distribution */}
              {quickActions.map((action, index) => (
                <Link to="/ravel-mpesa/" key={index} className="action p-4 bg-white rounded-lg shadow-sm flex flex-col items-center text-center">
                  {/* Icon */}
                  <div className="icon mb-2 bg-blue-100 text-blue-600 w-12 h-12 flex items-center justify-center rounded-full">
                    {action.icon}
                  </div>
                  {/* Action Name */}
                  <div className="text-xsm text-gray-800">{action.name}</div>
                </Link>
              ))}
            </div>
          </section>

        </div>
      </div>

      <div className="lg:grid lg:grid-cols-2 lg:gap-6 max-w-7xl mx-auto">
        {/* Recent transaction section */}
        <section className="mb-6 p-2 bg-white rounded-lg shadow">
          <div className="p-3">
            <h2 className="text-xl font-semibold mb-4">Recent Transactions</h2>
            {/* Transaction List */}
            {transactions.length === 0 ? (
              <p className="text-gray-600">See when money comes in, and when it goes out. You’ll find your recent Ravel activity here.</p>
            ) : (
              <ul className="space-y-2">
                {transactions.map((transaction) => (
                  <li key={transaction._id} className="flex justify-between items-center border-b py-3">
                    {/* Sender's icon and name */}
                    <div className="flex items-center text-sm">
                      <div className="flex-shrink-0  inline-flex items-center justify-center h-10 w-10 rounded-full bg-blue-500 text-white shadow-lg mr-2">
                        <span className="text-xl font-bold">
                          {transaction.sender === user?.primaryInfo?._id
                            ? transaction.receiverFirstName[0]?.toUpperCase()
                            : transaction.senderFirstName[0]?.toUpperCase()}
                        </span>
                      </div>

                      <div>
                        <p className="text-gray-900 dark:text-white font-semibold truncate">
                          {transaction.sender === user?.primaryInfo?._id
                            ? transaction.receiverFirstName
                            : transaction.senderFirstName}
                        </p>

                        <p className="text-xs text-gray-600">
                          {new Date(transaction.createdAt).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className={`text-sm ${transaction.sender === user?.primaryInfo?._id ? 'text-red-500' : 'text-green-500'} font-bold`}>
                        {transaction.sender === user?.primaryInfo?._id ? '-' : '+'}${transaction.amount}
                      </p>
                      <p className="text-xs text-gray-600">{transaction.status}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="px-4 py-2">
            <Link to="/activity" className="text-blue-500 text-sm">See all transactions</Link>
          </div>
        </section>


        {/* Second Section */}
        <section className="hidden lg:block lg:mb-6 lg:rounded-lg">
          <div className="p-4 bg-white rounded-lg shadow">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Dummy financial products/offers */}
              {[{ title: 'Loan', description: 'Special low interest rates' }, { title: 'Insurance', description: 'Comprehensive health coverage' } /* more products... */].map((product, index) => (
                <div key={index} className="financial-product p-4 border rounded-lg">
                  <h3 className="text-lg font-medium">{product.title}</h3>
                  <p className="text-sm text-gray-600">{product.description}</p>
                  <Link to={`/${product.title.toLowerCase()}`} className="text-blue-500 text-sm mt-2 inline-block">Learn more</Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Dashboard;