import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Table, Spinner } from 'react-bootstrap'; // Import Spinner for loading indicator
import api from '../../api';
import { useParams } from 'react-router-dom';
import { useNavigate, Link } from "react-router-dom";
import "./details.Module.css";
import {
    FaEnvelope, FaPhone, FaCalendar, FaMapMarker, FaUser, FaCity, FaFlag,
    FaIdCard, FaCreditCard, FaGift, FaClock
} from 'react-icons/fa';



const UserDetails = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [userInformation, setUserInformation] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // Add error state
    const navigate = useNavigate();
    const [withdrawals, setWithdrawals] = useState([]);
    const [buttonLoading, setButtonLoading] = useState({});


    const { userId } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                const userResp = await api.get(`api/user/${userId}`);
                const infoResp = await api.get(`api/information/${userId}`);
                const transResp = await api.get(`api/transactions/${userId}`);

                setUserInfo(userResp.data);
                setUserInformation(infoResp.data);
                setTransactions(transResp.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Error fetching user data. Please try again later."); // Set error message
                setLoading(false);
            }
        }

        async function fetchWithdrawals() {
            try {
                const response = await api.get(`api/withdrawals/${userId}`);
                if (response.data) {
                    setWithdrawals(response.data);
                } else {
                    console.error("No data returned");
                }
            } catch (error) {
                console.error("Error fetching withdrawals:", error);
            }
        }

        fetchData();
        fetchWithdrawals();
    }, [userId]);

    if (loading) {
        return (
            <div className="text-center mt-3">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-danger mt-3">
                {error}
            </div>
        );
    }

    const handleCancel = async (withdrawalId) => {
        setButtonLoading(prevState => ({ ...prevState, [withdrawalId]: true }));
        try {
            const response = await api.post(`api/withdrawals/${withdrawalId}/cancel`);
            if (response.status === 200) {
                const updatedWithdrawals = withdrawals.map(w =>
                    w._id === withdrawalId ? { ...w, status: 'failed' } : w  // or another appropriate status
                );
                setWithdrawals(updatedWithdrawals);
            } else {
                throw new Error(response.data.message || 'Failed to cancel the withdrawal.');
            }
        } catch (error) {
            console.error(`Error canceling withdrawal ${withdrawalId}: `, error.message);
            // Optionally handle the error with a user notification
        } finally {
            setButtonLoading(prevState => ({ ...prevState, [withdrawalId]: false }));
        }
    };

    const handleDelete = async (withdrawalId) => {
        setButtonLoading(prevState => ({ ...prevState, [withdrawalId]: true }));
        try {
            const response = await api.delete(`api/withdrawals/${withdrawalId}/delete`);
            if (response.status === 200) {
                const updatedWithdrawals = withdrawals.filter(w => w._id !== withdrawalId);
                setWithdrawals(updatedWithdrawals);
            } else {
                throw new Error(response.data.message || 'Failed to delete the withdrawal.');
            }
        } catch (error) {
            console.error(`Error deleting withdrawal ${withdrawalId}: `, error.message);
            // Optionally handle the error with a user notification
        } finally {
            setButtonLoading(prevState => ({ ...prevState, [withdrawalId]: false }));
        }
    };



    return (
        <div className="user-details-container">
            <Card className="mb-4 shadow">
                <Card.Header className="bg-primary text-white">Personal Information</Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <p><FaUser className="mr-2" /> First Name: <strong>{userInformation?.firstName}</strong></p>
                            <p><FaUser className="mr-2" /> Last Name: <strong>{userInformation?.lastName}</strong></p>
                            <p><FaCalendar className="mr-2" /> DOB: <strong>{new Date(userInformation?.dateOfBirth).toLocaleDateString()}</strong></p>
                            <p><FaMapMarker className="mr-2" /> PO Box: <strong>{userInformation?.poBox}</strong></p>
                            <p><FaMapMarker className="mr-2" /> Town: <strong>{userInformation?.town}</strong></p>
                            <p><FaCity className="mr-2" /> City: <strong>{userInformation?.city}</strong></p>
                            <p><FaFlag className="mr-2" /> Country: <strong>{userInformation?.country}</strong></p>
                        </Col>
                        <Col md={6}>
                            <p><FaEnvelope className="mr-2" /> Email: <strong>{userInformation?.email}</strong></p>
                            <p><FaPhone className="mr-2" /> Phone Number: <strong>{userInformation?.phoneNumber}</strong></p>
                            <p><FaCalendar className="mr-2" /> Last Login: <strong>{new Date(userInfo?.lastLogin).toLocaleString()}</strong></p>
                            <p><FaIdCard className="mr-2" /> Referral ID: <strong>{userInfo?.referralId}</strong></p>
                            <p><FaCreditCard className="mr-2" /> Pay ID: <strong>{userInfo?.payID}</strong></p>
                            <p><FaGift className="mr-2" /> Bonus: <strong>{userInfo?.bonus}</strong></p>
                            <p><FaClock className="mr-2" /> Created: <strong>{new Date(userInfo?.createdAt).toLocaleString()}</strong></p>
                            <Button
                                variant="outline-primary"
                                className="mt-2"
                                onClick={() => navigate(`/userAccounts/${userId}`)}
                            >
                                View accounts
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="mb-4 shadow">
                <Card.Header className="bg-primary text-white">Transactions</Card.Header>
                <Table striped bordered hover responsive className="mb-0">
                    <thead>
                        <tr>
                            <th>Transaction Type</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Sender</th>
                            <th>Receiver</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((trans, idx) => (
                            <tr key={idx}>
                                <td>{trans?.transactionType}</td>
                                <td>{trans?.transactionId}</td>
                                <td>${trans?.amount}</td>
                                <td>{trans?.senderFirstName}</td>
                                <td>{trans?.receiverFirstName}</td>
                                <td>{new Date(trans?.createdAt).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </Card>

            <Card className="shadow mb-4">
                <Card.Header className="bg-primary text-white">Withdrawals</Card.Header>
                {withdrawals.length > 0 ? (
                    <Table striped bordered hover responsive className="mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Transaction ID</th>
                                <th>Cancel</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {withdrawals.map((withdrawal, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{new Date(withdrawal.createdAt).toLocaleDateString()}</td>
                                    <td>{withdrawal.amount}</td>
                                    <td>{withdrawal.status}</td>
                                    <td>{withdrawal.transactionId}</td>
                                    <td>
                                        <Button
                                            variant={withdrawal.status !== 'pending' || buttonLoading[withdrawal._id] ? "secondary" : "warning"}
                                            disabled={withdrawal.status !== 'pending' || buttonLoading[withdrawal._id]}
                                            onClick={() => handleCancel(withdrawal._id)}
                                        >
                                            {buttonLoading[withdrawal._id] ? 'Canceling...' : 'Cancel'}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            disabled={buttonLoading[withdrawal._id]}
                                            onClick={() => handleDelete(withdrawal._id)}
                                        >
                                            {buttonLoading[withdrawal._id] ? 'Deleting...' : 'Delete'}
                                        </Button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <Card.Body className="text-center">No records found.</Card.Body>
                )}
            </Card>

        </div>
    );
}

export default UserDetails;
