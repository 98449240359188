import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Navbar } from "../SoleComponents";
import { useUser } from "../context";
import api from "../../api";
import Lottie from "lottie-react";
import successAnimation from "./success-animation.json";
import { useNavigate } from "react-router-dom";

const Convert = () => {
  const { user, login } = useUser();
  const accounts = user?.accounts;
  const navigate = useNavigate();

  const getUniqueCurrencies = (accounts) => {
    const uniqueCurrencies = Array.from(
      new Set(accounts.map((account) => account.currency))
    );
    return uniqueCurrencies;
  };

  const currencies = getUniqueCurrencies(accounts);

  const [fromCurrency, setFromCurrency] = useState("USD");
  const [toCurrency, setToCurrency] = useState("KES");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [balance, setBalance] = useState(0);
  const isExceedingBalance = parseFloat(amount) > balance;
  const availableCurrencies = useMemo(() => {
    return currencies.filter(currency => currency !== fromCurrency);
  }, [currencies, fromCurrency]);

  // State to store the amount, source currency, and target currency
  const [timer, setTimer] = useState(5);

  const rates = {
    "USD": { "GBP": 0.76, "KES": 157.00 },
    "GBP": { "USD": 1.32, "KES": 171.05 },
    "KES": { "USD": 0.0077, "GBP": 0.0058 },
  };


  useEffect(() => {
    const account = accounts.find(acc => acc.currency === fromCurrency);
    if (account) {
      setBalance(account.balance);
    } else {
      setBalance(0); // Set to 0 or some default value if no matching account is found
    }
  }, [fromCurrency, accounts]);

  // This useEffect handles the resetting of toCurrency
  useEffect(() => {
    if (toCurrency === fromCurrency) {
      setToCurrency("");
    }
  }, [fromCurrency, toCurrency]);

  const handleSwapCurrencies = useCallback(() => {
    // Swap the currencies
    setAmount('');
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
  }, [fromCurrency, toCurrency]);



  // Mock function to simulate fetching exchange rates

  useEffect(() => {
    // Countdown logic
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  useEffect(() => {
    // Reset timer logic
    if (timer === 0) {
      // Wait for 15 seconds before resetting the timer
      const timeout = setTimeout(() => {
        setTimer(15); // Reset to 5 seconds for the countdown (or choose another duration)
      }, 15000); // 15 seconds

      return () => clearTimeout(timeout);
    }
  }, [timer]);




  // Function to get the current exchange rate
  const getCurrentRate = () => {
    return rates[fromCurrency] && rates[fromCurrency][toCurrency]
      ? rates[fromCurrency][toCurrency]
      : 0; // Return 0 if no rate available
  };




  const handleFromCurrencyChange = (e) => {
    setFromCurrency(e.target.value);
  };

  const handleToCurrencyChange = (e) => {
    setToCurrency(e.target.value);
  };

  const setMaxAmount = () => {
    setAmount(balance.toString());
  };



  const calculateConversion = () => {
    if (amount && rates[fromCurrency] && rates[fromCurrency][toCurrency]) {
      const rate = rates[fromCurrency][toCurrency];
      const result = parseFloat(amount) * rate;
      setConvertedAmount(result.toFixed(2));
    } else {
      setConvertedAmount(0);
    }
  };

  useEffect(() => {
    calculateConversion();
  }, [amount, fromCurrency, toCurrency, rates]);

  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    setAmount(enteredAmount);
  };

  const handleConvertSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setShowSuccess(false);

    // Check if fromCurrency and toCurrency are the same
    if (fromCurrency === toCurrency) {
      setError("From currency and to currency cannot be the same.");
      setLoading(false);
      return;
    }

    // Check if the amount is less than the minimum conversion amount
    if (parseFloat(amount) < 10) {
      setError("Minimum conversion amount is 10.");
      setLoading(false);
      return;
    }
    try {
      const response = await api.post(
        "/api/conversion",
        {
          fromCurrency,
          toCurrency,
          amount,
          userId: user.primaryInfo?._id,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response.status === 201) {
        const balanceResponse = await api.get("/api/getUserBalances", {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "user-id": user?.primaryInfo?._id,
          },
        });

        if (balanceResponse.status === 200) {
          // Update the local storage with the new balances
          const updatedUser = {
            ...user,
            accounts: balanceResponse.data.accounts,
          };
          console.log(updatedUser);
          localStorage.setItem("user", JSON.stringify(updatedUser));

          // Update the context
          login(updatedUser);
        }
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          navigate("/currencies");
        }, 4000);
      } else {
        setError("An error occurred while processing your conversion.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred while processing your conversion.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showSuccess ? (
        <div className="flex items-center justify-center">
          <div className="flex justify-center items-center p-4">
            <div className="bg-white shadow-xl rounded-lg overflow-hidden p-5 space-y-4">

              {/* Success Animation */}
              <Lottie animationData={successAnimation} className="w-48 h-48 mx-auto" style={{ width: '200px', height: '200px' }} />

              <h2 className="text-3xl font-extrabold text-gray-800 text-center">🎉 Conversion Successful</h2>
              {/* Conversion Details */}
              <div className="text-left space-y-2">
                <div className="flex justify-between text-xsm text-gray-700">
                  <span className="font-semibold">From Currency:</span>
                  <span>{fromCurrency}</span>
                </div>
                <div className="flex justify-between text-xsm text-gray-700">
                  <span className="font-semibold">To Currency:</span>
                  <span>{toCurrency}</span>
                </div>
                <div className="flex justify-between text-xsm text-gray-700">
                  <span className="font-semibold">Amount:</span>
                  <span>{`${amount} ${fromCurrency}`}</span>
                </div>
                <div className="flex justify-between text-xsm text-gray-700">
                  <span className="font-semibold">Converted Amount:</span>
                  <span>{`${convertedAmount} ${toCurrency}`}</span>
                </div>
                <div className="flex justify-between text-xsm text-gray-700">
                  <span className="font-semibold">Date:</span>
                  <span>
                    {new Date().toLocaleString("en-US", {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })}
                  </span>
                </div>
              </div>

              {/* Dotted line divider */}
              <div style={{ borderTop: "3px dotted #999", borderRadius: "1px" }} className="my-4"></div>

              {/* Here you could potentially add a CTA like 'New Conversion' or anything relevant */}
              {/* For example, a button to perform a new conversion. This is optional. */}
              <div className="flex justify-center">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center"

                >
                  Perform New Conversion
                </button>
              </div>

              {/* Notes or any additional information */}
              <div className="text-center mt-4">
                <p className="text-xs text-gray-700">Please note the conversion rate may vary in real-time.</p>
              </div>

              {/* Optional: Signature Line */}
              <div className="text-center mt-4">
                <p className="text-xs text-gray-700">Authorized Signature: ___________________</p>
              </div>

            </div>
          </div>
        </div>
      ) : (
        <>
          <Navbar />
          <div className='bg-white p-4 sm:p-6 rounded border border-gray-200 max-w-2xl w-full mx-auto my-4'>
            {error && (
              <div className='mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded relative' role='alert'>
                <strong className='font-bold'>Error: </strong>
                <span className='block sm:inline'>{error}</span>
                <span className='absolute top-0 bottom-0 right-0 px-4 py-3'>

                </span>
              </div>
            )}

            {/* Currency selection section */}
            <div className='flex flex-col sm:flex-row lg:items-start items-center justify-between mb-4'>
              {/* From Currency Select */}
              <div className='w-full mb-4 sm:mb-0 sm:mr-2'>
                <select
                  value={fromCurrency}
                  onChange={handleFromCurrencyChange}
                  className='block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded leading-tight focus:outline-none focus:shadow-outline'
                >
                  {currencies.map(currency => (
                    <option key={currency} value={currency}>{currency}</option>
                  ))}
                </select>
              </div>

              <div className='flex justify-center  items-center mx-4 mb-4 sm:mb-0 sm:mx-6'>
                <button className='rounded-full shadow-lg bg-white-500 p-2 focus:outline-none' onClick={handleSwapCurrencies} type="button">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="sm:rotate-0 rotate-90">
                    <path d="M8.1 11.1c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L6.8 7.8H19c.4 0 .8-.3.8-.8s-.4-.8-.8-.8H6.8l2.3-2.3c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0L4.5 6.5c-.3.3-.3.8 0 1.1zm7.8 1.8c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l2.3 2.3H5c-.4 0-.8.3-.8.8s.3.8.8.8h12.2l-2.3 2.3c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l3.6-3.6c.1-.1.2-.3.2-.5s-.1-.4-.2-.5z"></path>
                  </svg>
                </button>
              </div>
              {/* To Currency Select */}
              <div className='w-full sm:ml-2'>
                <select
                  value={toCurrency}
                  onChange={handleToCurrencyChange}
                  className='block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded leading-tight focus:outline-none focus:shadow-outline'
                >
                  {availableCurrencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Amount input section */}
            <div className='flex flex-col sm:flex-row justify-between items-start mb-4 gap-x-4'>
              {/* From amount input */}
              <div className='w-full mb-4 sm:mb-0'>
                <label className='block text-gray-700 text-xsm font-bold mb-2'>
                  You are converting
                </label>
                <input
      type='tel'
      className={`appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${isExceedingBalance ? 'border-2 border-red-500' : 'border'}`}
      placeholder='Enter amount'
      value={amount}
      onChange={handleAmountChange}
    />

<div 
        className='mt-2 text-xsm text-gray-600 border-1 border border-gray-400 p-1 inline-block focus:bg-white focus:bg-opacity-5 outline-none cursor-pointer'
        onClick={setMaxAmount}
      >
        Max: {balance}
      </div>
              </div>

              {/* To amount display */}
              <div className='w-full'>
                <label className='block text-gray-700 text-xsm font-bold mb-2'>
                  You will receive
                </label>
                <input
                  type='tel'
                  disabled
                  className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  placeholder='Enter amount'
                  value={convertedAmount}
                />
              </div>
            </div>




            {/* Exchange rate and timer alert */}
            <div className='mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded text-xsm' role='alert'>
              <div className='font-bold'>Exchange Rate: <span>{`${fromCurrency} 1 = ${toCurrency} ${getCurrentRate()}`}</span></div>
              <div className='mt-2'>
                {timer === 0 ? 'Refreshing...' : `Refreshing in ${timer} second${timer > 1 ? 's' : ''}`}
              </div>
            </div>
            {/* Convert button */}
            <div className='flex justify-center mt-4'>
            <button
  className='w-full sm:w-auto border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out'
  onClick={handleConvertSubmit}
  disabled={
    !amount ||
    !fromCurrency ||
    !toCurrency ||
    loading
  }
>
  {loading ? "Processing..." : "Convert"}
</button>


            </div>
          </div>


        </>
      )}
    </>
  );
};

export default Convert;
