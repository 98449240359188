import React, { useState, useEffect } from "react";
import styles from "./Widthdraw.module.css";
import { Navbar } from "../SoleComponents";
import { useUser } from "../context";
import api from "../../api";
import Lottie from "lottie-react";
import successAnimation from "./success-animation.json";
import { useNavigate } from "react-router-dom";

const Withdrawbonus = () => {
  const { user } = useUser();
  const accounts = user?.accounts;
  const navigate = useNavigate();

  const getUniqueCurrencies = (accounts) => {
    const uniqueCurrencies = Array.from(new Set(accounts.map((account) => account.currency)));
    uniqueCurrencies.sort((a, b) => {
      const balanceA = accounts.find((account) => account.currency === a)?.balance || 0;
      const balanceB = accounts.find((account) => account.currency === b)?.balance || 0;
      return balanceB - balanceA;
    });
    return uniqueCurrencies;
  };

  const currencies = getUniqueCurrencies(accounts);

  const highestBalanceCurrency = currencies.find((currency) => {
    const balance = accounts.find((account) => account.currency === currency)?.balance || 0;
    return balance > 0;
  });
  const initialSelectedCurrency = highestBalanceCurrency || "USD";
  const [selectedCurrency, setSelectedCurrency] = useState(initialSelectedCurrency);
  const [amountwithdrawal, setAmountWithdrawal] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [withdrawalResponse, setWithdrawalResponse] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pendingWithdrawal, setPendingWithdrawal] = useState(null);
  const [isValid, setIsValid] = useState(false);


  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setAmountWithdrawal(amount);

    if (!isNaN(amount) && amount > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };



  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.post('/api/withdrawalBonus', {
        amount: parseFloat(amountwithdrawal),
        currency: "KES",
        userId: user.primaryInfo?._id,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setWithdrawalResponse(response.data); 
      setShowSuccess(true);
      setError(null);

      setTimeout(() => {
        setShowSuccess(false);
        navigate("/home");
      }, 4000);
    } catch (error) {
      // Handle error
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  


  function generateRandomTransactionNumber() {
    const prefix = "RGP";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = prefix;
    for (let i = 0; i < 5; i++) { 
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  const transID = generateRandomTransactionNumber();

  return (
    showSuccess ? (
      <div className="flex items-center justify-center">
        <div className="bg-white rounded-lg overflow-hidden p-5 space-y-4">
          {/* Success Animation */}
          <Lottie animationData={successAnimation} className="w-48 h-48 mx-auto" style={{ width: '200px', height: '200px' }} />
    
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-800">🎉 Successful</h2>
          </div>
    
          <div className="text-left space-y-2">
            <p className="text-sm text-gray-700">
              <strong>Withdraw ID:</strong> {transID}
            </p>
            <p className="text-sm text-gray-700">
              <strong>Channel:</strong> Mpesa
            </p>
            <p className="text-sm text-gray-700">
              <strong>Amount:</strong> {amountwithdrawal} KES
            </p>
            <p className="text-sm text-gray-700">
              <strong>Date:</strong> {new Date().toLocaleString("en-US", { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}
            </p>
          </div>
    
          <div className="text-center mt-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
              Download Receipt
            </button>
          </div>
    
          <div className="text-center mt-4">
            <p className="text-xs text-gray-700">Thank you for your transaction. Keep this receipt for your records.</p>
          </div>
    
          <div className="text-center mt-4">
            <p className="text-xs text-gray-700">Authorized Signature: ___________________</p>
          </div>
        </div>
      </div>
    ) : (
      <>
        <Navbar />
        <section className={styles.pyplWithdrawSection}>
          <div className={styles.mainWithdrawContent}>
            <div className={styles.introHeader}>
              <h1>Withdraw from your Ravel account</h1>
            </div>

            <div className={styles.withdrawWrapperSplitter}>
              <div className={styles.withdrawContainer}>
                <h5>
                  Available bonus in your Ravel account:
                  {error && <p className={styles.errorMessage}>{error}</p>}
                  <div className={styles.balanceContainer}>
                    <h5 className={styles.balance}>{user?.primaryInfo?.bonus?.toFixed(2)}</h5>
                    <select
  className={styles.customSelect}
  name=""
  id=""
  value={selectedCurrency}
  onChange={handleCurrencyChange}
>
  <option value="KES">KES (Kenyan Shillings)</option>
</select>

                  </div>
                </h5>
                <p>Please enter the amount you would like to withdraw from your Ravel account:</p>
                <div className={styles.inputContainer}>
                  <input
                    type="tel"
                    placeholder="Amount"
                    name="amount"
                    id=""
                    value={amountwithdrawal}
                    onChange={handleAmountChange}
                    required
                  />
                  <select name="" id="" className={styles.customSelect}>
                    <option value="KES" selected>
                      KES
                    </option>
                  </select>
                </div>
                <div className={styles.buttonContainer}>
                  <button disabled={loading || !isValid} onClick={handleSubmit}>
                    {loading ? 'Processing...' : 'Continue to Withdraw'}
                  </button>
                </div>
              </div>
              <div className={styles.svgTransferIllustration}>
                <img src="/ravel-withdraw.png" alt="transfer-illustration" />
              </div>
            </div>
          </div>
          <div className={styles.importantInfo}>
            <p>
              Please note that the maximum amount per transaction is 150,000 KES and you can only hold up to 300,000 KES
              in your M-PESA account. Make sure your M-PESA account can hold your withdrawal balance.
            </p>
          </div>

        </section>
      </>
    )
  );
};

export default Withdrawbonus;
