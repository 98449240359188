import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import api from '../../api';
import { useNavigate } from "react-router-dom";

function Pending() {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pendingWithdrawals, setPendingWithdrawals] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [currentWithdrawal, setCurrentWithdrawal] = useState(null);
  const navigate = useNavigate();


  const viewMoreDetails = (withdrawal) => {
    navigate(`/details/${withdrawal.user}`);
}

  useEffect(() => {
    const fetchPendingWithdrawals = async () => {
      try {
        const response = await api.get('api/pending-withdrawals-count');
        setWithdrawals(response.data.pendingWithdrawalsData);
        setPendingWithdrawals(response.data.pendingWithdrawalsCount);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingWithdrawals();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  // Function to calculate the difference in hours
  const hoursSinceCreated = (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate - creationDate;
    const differenceInHours = differenceInTime / (1000 * 3600); // Convert milliseconds to hours

    // Use Math.floor to round down, ensuring it's a whole number
    return Math.abs(Math.floor(differenceInHours));
  };


  const handleShowModal = (withdrawal) => {
    setCurrentWithdrawal(withdrawal);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentWithdrawal(null);
  };

  const confirmComplete = async () => {
    try {
      const response = await api.post('api/complete-transaction', { id: currentWithdrawal._id });

      // Check response or refresh data if needed
      if (response.status === 200) {
        const updatedResponse = await api.get('api/pending-withdrawals-count');
        setWithdrawals(updatedResponse.data.pendingWithdrawalsData);
        setPendingWithdrawals(updatedResponse.data.pendingWithdrawalsCount);
      }
    } catch (error) {
      console.error('Error completing the transaction:', error.message);
    }

    handleCloseModal();
  };


  const handleComplete = (withdrawal) => {
    handleShowModal(withdrawal);
  };

  const handleShowCancelModal = (withdrawal) => {
    setCurrentWithdrawal(withdrawal);
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setCurrentWithdrawal(null);
  };
  const handleCancel = (withdrawal) => {
    handleShowCancelModal(withdrawal);
  };


  const confirmCancel = async () => {
    try {
      const response = await api.post('api/cancel-transaction', { id: currentWithdrawal._id });

      // Refresh data after cancellation if needed
      if (response.status === 200) {
        const updatedResponse = await api.get('api/pending-withdrawals-count');
        setWithdrawals(updatedResponse.data.pendingWithdrawalsData);
        setPendingWithdrawals(updatedResponse.data.pendingWithdrawalsCount);
      }
    } catch (error) {
      console.error('Error cancelling the transaction:', error.message);
    }

    handleCloseCancelModal();
  };


  return (
    <div className="container mt-3">
      <h4>Pending Withdrawals({pendingWithdrawals})</h4>
      <div className="table-responsive">  {/* Make table responsive */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Hours Since</th>
              <th>View</th>
              <th>Complete</th>
              <th>Cancel</th>
            </tr>
          </thead>
          <tbody>
            {withdrawals.map(withdrawal => (
              <tr key={withdrawal._id}>
                <td>{withdrawal.transactionId}</td>
                <td>{withdrawal.email}</td>
                <td>{withdrawal.phoneNumber}</td>
                <td>{withdrawal.amount}</td>
                <td>{withdrawal.currency}</td>
                <td>{hoursSinceCreated(withdrawal.createdAt)}Hrs</td>
                <td>
                  <Button variant="primary"  onClick={() => viewMoreDetails(withdrawal)}><i className="fas fa-eye"></i>View</Button>
                </td>
                <td>
                  <Button variant="success" onClick={() => handleComplete(withdrawal)}>Complete</Button>
                </td>
                <td>
                  <Button variant="danger" onClick={() => handleCancel(withdrawal)}>Cancel</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send {currentWithdrawal?.amount} to user {currentWithdrawal?.email}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={confirmComplete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancelModal} onHide={handleCloseCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel the transaction for user {currentWithdrawal?.email}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancelModal}>
            Close
          </Button>
          <Button variant="danger" onClick={confirmCancel}>
            Confirm Cancel
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Pending;
